import { LocationInfo, UserMapLocation, Waypoint } from "@anw/gor-sdk";
import { OpeningHours, TimeZone } from "@tomtom-international/web-sdk-services";
import { SearchIntention } from "../state/tree/map-page/search/types";
import { ResultType } from "../services/ngs/ngsAPITypes";

/**
 * Extension of GOR's LocationInfo with some fields relevant to fresh objects, like localized values.
 */
export type TTMLocationInfo = LocationInfo & {
    localizedCountryName?: string;
    localizedPOIClassificationNames?: string[];
    openingHours?: OpeningHours;
    timeZone?: TimeZone;
    ngsType?: ResultType;
    name?: string;
    description?: string;
    link?: string;
    intention?: SearchIntention;
};

/**
 * Context of a location: where it comes from.
 */
export enum TTMLocationContext {
    SEARCH_RESULT,
    SEARCH_HISTORY,
    MAP_POI,
    WAYPOINT,
    MAP_POINT,
    MY_PLACES,
    ACTIVE_DESTINATION
}

/**
 * Extension of GOR's Waypoint with overwritten type for its location info.
 */
export interface TTMWaypoint extends Waypoint {
    locationInfo?: TTMLocationInfo;
    isCurrentLocation?: boolean;
    context?: TTMLocationContext;
}

/**
 * Basic extension of TTMLocationInfo to facilitate extensibility with fields only related to actual search results.
 */
export type TTMSearchResult = TTMLocationInfo;

export type Searchable = {
    /**
     * Simplified version of the place for in-memory search purposes.
     */
    searchText: string;
};

export type TTMSearchableResult = TTMSearchResult & Searchable;

/**
 * Extension of GOR's UserMapLocation with convenience fields for TTM.
 */
export type TTMUserMapLocation = UserMapLocation & Searchable;

/**
 * Main generic location type.
 */
export type TTMLocation = (TTMLocationInfo | TTMSearchResult | TTMWaypoint | TTMUserMapLocation) & {
    context: TTMLocationContext;
};

export type Heading = { title: string; subtitle: string };
