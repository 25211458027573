import { TTMLocation } from "./locationTypes";
import { getLocationInfo } from "./location";
import { toFixedLngLat } from "./numbers";
import { URL_PATH_MAP } from "../components/elements/Links";
import { LngLatLike } from "@tomtom-international/web-sdk-services";
import { Point } from "@anw/gor-sdk";

export const encodeURLPathAddress = (str: string): string => {
    if (!str) {
        return str;
    }
    return str.trim().replace(/\s/g, "_").replace(/[.]/g, "~").replace(/,/g, "-");
};

export const decodeURLPathAddress = (urlPathAddress: string): string => {
    if (!urlPathAddress) {
        return urlPathAddress;
    }
    return urlPathAddress.replace(/_/g, " ").replace(/~/g, ".").replace(/-/g, ",");
};

export const encodeURLPathCoordinates = (point: Point): string =>
    `${toFixedLngLat(point[0])}~${toFixedLngLat(point[1])}`.replace(/[.]/g, "_");

export const decodeURLPathCoordinates = (urlPathCoords: string): LngLatLike => {
    const [lat, lng] = urlPathCoords.split("~").map((s) => parseFloat(s.replace(/_/g, ".")));
    return [lng, lat];
};

export const encodeLocationURLPath = (location: TTMLocation): string => {
    const locationInfo = getLocationInfo(location);
    if (locationInfo?.externalID) {
        return `${URL_PATH_MAP.LOCATION_ID_BASE}/${locationInfo.externalID}`;
    } else {
        return null;
    }
};
