import {
    BackgroundLayerSpecification,
    ExpressionSpecification,
    FillLayerSpecification,
    LineLayerSpecification,
    SymbolLayerSpecification
} from "maplibre-gl";
import parse from "parse-css-color";
import isEmpty from "lodash/isEmpty";

import { MapStyleEdit } from "../state/tree/map-page/map-controls/reducers";
import MapProvider from "../classes/map/MapProvider";

export type Hsla = { hue?: number; saturationPCT?: number; lightnessPCT?: number; alpha?: number };
export type MapStyleQuickProps = Hsla & { lineWidth?: number; textSize?: number; swapTextColorWithHalo?: boolean };

export type StyleLayerGroup =
    | "AllBaseMap"
    | "Background"
    | "Water"
    | "Buildings"
    | "Landcover"
    | "Landuse"
    | "Border"
    | "roads"
    | "Places"
    | "Traffic";

export type LayerGroupsFilter = { include?: StyleLayerGroup[]; exclude?: StyleLayerGroup[] };

export type MapStyleEditInput = { stylePropOffsets: MapStyleQuickProps; layerGroupsFilter?: LayerGroupsFilter };

const changeHslaInColorBy = (color: string, hslOffset: Hsla): string => {
    const parsedColor = parse(color);
    if (!parsedColor) {
        return null;
    }
    const values = parsedColor.values;
    const pctSuffix = parsedColor.type == "hsl" ? "%" : "";
    const hue = hslOffset.hue ? values[0] + (hslOffset.hue % 360) : values[0];
    const saturation = hslOffset.saturationPCT
        ? Math.max(Math.min(values[1] + hslOffset.saturationPCT, 100), 0)
        : values[1];
    const lightness = hslOffset.lightnessPCT
        ? Math.max(Math.min(values[2] + hslOffset.lightnessPCT, 100), 0)
        : values[2];
    const alpha = hslOffset.alpha ? Math.max(Math.min(parsedColor.alpha + hslOffset.alpha, 1), 0) : parsedColor.alpha;
    return `${parsedColor.type}a(${hue}, ${saturation}${pctSuffix}, ${lightness}${pctSuffix}, ${alpha})`;
};

export const changeHslaInColorPropBy = <T = string | ExpressionSpecification>(
    colorProp: T,
    hslOffset: MapStyleQuickProps
): T => {
    if (!Array.isArray(colorProp)) {
        return changeHslaInColorBy(colorProp as unknown as string, hslOffset) as unknown as T;
    } else {
        return colorProp.map((value) => {
            if (Array.isArray(value)) {
                // Recursively going through nested expressions:
                return changeHslaInColorPropBy(value, hslOffset);
            } else {
                const changedColor = changeHslaInColorBy(value, hslOffset);
                return changedColor || value;
            }
        }) as unknown as T;
    }
};

const changeNumericValueBy = <T = number | ExpressionSpecification>(numericProp: T, offset: number): T => {
    if (typeof numericProp == "number") {
        return (numericProp + offset) as unknown as T;
    } else if (Array.isArray(numericProp)) {
        let numericEvenIndex = true;
        return numericProp.map((value, index) => {
            if (Array.isArray(value)) {
                // Recursively going through nested expressions:
                return changeNumericValueBy(value, offset);
            } else if (typeof value == "number") {
                numericEvenIndex = !numericEvenIndex;
                // (detecting if previous number is a zoom value)
                if (
                    index > 1 &&
                    ((numericEvenIndex && typeof numericProp[index - 1] == "number") ||
                        numericProp.length == index + 1 ||
                        (Array.isArray(numericProp[index - 1]) && Array.isArray(numericProp[index + 1])))
                ) {
                    return Math.max(value + offset, 1);
                } else {
                    return value;
                }
            } else {
                return value;
            }
        }) as unknown as T;
    } else {
        return numericProp;
    }
};

export const changeStyleQuickProps = (props: MapStyleEditInput): void => {
    const map = MapProvider.map;
    const mapLayers = map.getStyle().layers;
    const layerGroupsFilter = props.layerGroupsFilter;
    const stylePropOffsets = props.stylePropOffsets;

    mapLayers
        .filter((layer) => layer.type == "symbol")
        .filter(
            (layer) =>
                !layerGroupsFilter ||
                ((isEmpty(layerGroupsFilter?.include) ||
                    layerGroupsFilter?.include?.some((group) => layer.id.includes(group))) &&
                    (isEmpty(layerGroupsFilter?.exclude) ||
                        !layerGroupsFilter?.exclude?.some((group) => layer.id.includes(group))))
        )
        .forEach((layer: SymbolLayerSpecification) => {
            const textColor = layer.paint?.["text-color"];

            const changedTextColor = textColor && changeHslaInColorPropBy(textColor, stylePropOffsets);
            changedTextColor && map.setPaintProperty(layer.id, "text-color", changedTextColor);

            const textHaloColor = layer.paint?.["text-halo-color"];
            if (stylePropOffsets.swapTextColorWithHalo && textColor && textHaloColor) {
                map.setPaintProperty(layer.id, "text-color", textHaloColor);
                map.setPaintProperty(layer.id, "text-halo-color", textColor);
            }

            if (stylePropOffsets.textSize) {
                const textSize = layer.layout?.["text-size"];
                const changedTextSize = textSize && changeNumericValueBy(textSize, stylePropOffsets.textSize);
                changedTextSize && map.setLayoutProperty(layer.id, "text-size", changedTextSize);
            }
        });

    mapLayers
        .filter((layer) => layer.type == "line")
        .filter(
            (layer: LineLayerSpecification) =>
                !layerGroupsFilter ||
                ((isEmpty(layerGroupsFilter?.include) ||
                    layerGroupsFilter?.include.some(
                        (group) => layer.id.includes(group) || layer["source-layer"]?.includes(group)
                    )) &&
                    (isEmpty(layerGroupsFilter?.exclude) ||
                        !layerGroupsFilter?.exclude.some(
                            (group) => layer.id.includes(group) || layer["source-layer"]?.includes(group)
                        )))
        )
        .forEach((layer: LineLayerSpecification) => {
            const changedProp = changeHslaInColorPropBy(layer.paint["line-color"], stylePropOffsets);
            changedProp && map.setPaintProperty(layer.id, "line-color", changedProp);
            if (stylePropOffsets.lineWidth) {
                const lineWidth = layer.paint?.["line-width"];
                const changedLineWidth = lineWidth && changeNumericValueBy(lineWidth, stylePropOffsets.lineWidth);
                changedLineWidth && map.setPaintProperty(layer.id, "line-width", changedLineWidth);

                const lineGapWidth = layer.paint?.["line-gap-width"];
                const changedLineGapWidth =
                    lineGapWidth && changeNumericValueBy(lineGapWidth, stylePropOffsets.lineWidth);
                changedLineGapWidth && map.setPaintProperty(layer.id, "line-gap-width", changedLineGapWidth);
            }
        });

    mapLayers
        .filter((layer) => layer.type == "fill")
        .filter(
            (layer: FillLayerSpecification) =>
                !layerGroupsFilter ||
                ((isEmpty(layerGroupsFilter?.include) ||
                    layerGroupsFilter?.include.some((group) => layer.id.includes(group))) &&
                    (isEmpty(layerGroupsFilter?.exclude) ||
                        !layerGroupsFilter?.exclude.some((group) => layer.id.includes(group))))
        )
        .forEach((layer: FillLayerSpecification) => {
            const changedProp = changeHslaInColorPropBy(layer.paint["fill-color"], stylePropOffsets);
            changedProp && map.setPaintProperty(layer.id, "fill-color", changedProp);
        });

    if (
        !layerGroupsFilter ||
        ((isEmpty(layerGroupsFilter?.include) || layerGroupsFilter?.include.includes("Background")) &&
            (isEmpty(layerGroupsFilter?.exclude) || !layerGroupsFilter?.exclude.includes("Background")))
    ) {
        mapLayers
            .filter((layer) => layer.type == "background")
            .forEach((layer: BackgroundLayerSpecification) => {
                const changedProp = changeHslaInColorPropBy(layer.paint["background-color"], stylePropOffsets);
                changedProp && map.setPaintProperty(layer.id, "background-color", changedProp);
            });
    }
};

export const diffMapStyleEdits = (curr: MapStyleEdit, prev: MapStyleEdit): MapStyleEdit => {
    if (!prev) {
        return curr;
    } else {
        const result = { ...curr };
        Object.keys(result).forEach((layerGroup) => {
            // (original object is read-only so we make some partial copies to be able to write on it)
            const layerGroupEdit = { ...result[layerGroup] };
            const resultPropOffsets = { ...layerGroupEdit?.stylePropOffsets };
            const prevPropOffsets = prev[layerGroup]?.stylePropOffsets;
            if (resultPropOffsets && prevPropOffsets) {
                Object.keys(resultPropOffsets).forEach((propName) => {
                    if (
                        prevPropOffsets[propName] &&
                        resultPropOffsets[propName] &&
                        typeof resultPropOffsets[propName] == "number"
                    ) {
                        if (resultPropOffsets[propName] == prevPropOffsets[propName]) {
                            delete resultPropOffsets[propName];
                        } else {
                            resultPropOffsets[propName] -= prevPropOffsets[propName];
                        }
                    }
                });
            }
            if (resultPropOffsets && !Object.keys(resultPropOffsets).length) {
                delete result[layerGroup];
            } else {
                layerGroupEdit.stylePropOffsets = resultPropOffsets;
                result[layerGroup] = layerGroupEdit;
            }
        });
        return result;
    }
};
