import {
    GeoInput,
    ItineraryPlanningRequest,
    ItinerarySectionGeoInput,
    LocationInfo,
    Point,
    Waypoint
} from "@anw/gor-sdk";
import omit from "lodash/omit";
import juri from "juri";
import { isChargingStop } from "./waypoint";

const juriInstance = juri();

const toURLWaypoint = (geoInput: GeoInput): string => {
    if (geoInput.type === "SOFT") {
        const waypoint = geoInput as Waypoint;
        return `s|${waypoint.pointLatLon[0]};${waypoint.pointLatLon[1]};${waypoint.radius}`;
    } else if (geoInput.type === "HARD" && !isChargingStop(geoInput)) {
        const waypoint = geoInput as Waypoint;
        let waypointStr = `h|${waypoint.pointLatLon[0]};${waypoint.pointLatLon[1]}`;
        if (waypoint.locationInfo) {
            if (waypoint.locationInfo.type === "POI") {
                waypointStr += `|poi;${waypoint.locationInfo.externalID};${waypoint.locationInfo.poiName}`;
            } else {
                waypointStr += `|addr;${waypoint.locationInfo.formattedAddress}`;
            }
        }
        return waypointStr;
    }
};

const toURLWaypoints = (geoInputs: GeoInput[]): string[] =>
    geoInputs?.map((geoInput) => toURLWaypoint(geoInput)).filter((string) => string);

export const toURLPlanningCriteriaObject = (request: ItineraryPlanningRequest) =>
    request && {
        ...omit(
            request,
            "numAlternatives",
            "compareWithHistoricTrafficItinerary",
            "timeIntervalMinutes",
            "distanceIntervalKMs",
            "includeGuidanceInFullView",
            "sortedGeoInputs",
            "unsortedGeoInputs"
        ),
        sorted: toURLWaypoints(request.sortedGeoInputs),
        ...(request.unsortedGeoInputs && { unsorted: toURLWaypoints(request.unsortedGeoInputs) })
    };

export const toURLPlanningCriteria = (request: ItineraryPlanningRequest): string =>
    request && juriInstance.encode(toURLPlanningCriteriaObject(request));

const fromURLWaypoint = (urlWaypoint: string): Waypoint | ItinerarySectionGeoInput => {
    const parts = urlWaypoint.split("|");
    const type = parts[0] === "s" ? "SOFT" : "HARD";
    const coordsPart = parts[1].split(";");
    const pointLatLon = [Number(coordsPart[0]), Number(coordsPart[1])] as Point;
    const radius = type === "SOFT" && Number(coordsPart[2]);
    let poiID = null;
    let poiName = null;
    let formattedAddress = null;
    if (type === "HARD") {
        const poiPart = parts.slice(2).find((part) => part.startsWith("poi"));
        if (poiPart) {
            const poiParts = poiPart.split(";");
            // first poiPart is "poi;"
            poiID = poiParts[1];
            poiName = poiParts[2];
        }
        const addressPart = parts.slice(2).find((part) => part.startsWith("addr"));
        if (addressPart) {
            formattedAddress = addressPart.split(";")[1];
        }
    }
    let locationInfo = null as LocationInfo;
    if (poiID || poiName || formattedAddress) {
        locationInfo = {
            type: poiID ? "POI" : "ADDRESS",
            point: pointLatLon,
            ...(formattedAddress && { formattedAddress }),
            // (we ensure to always have some ID)
            externalID: poiID ? poiID : `random${Math.random()}`,
            ...(poiName && { poiName })
        };
    }
    return {
        type,
        pointLatLon,
        ...(radius && { radius }),
        ...(locationInfo && { locationInfo })
    } as Waypoint;
};

const fromURLWaypoints = (urlWaypoints: string[]): (Waypoint | ItinerarySectionGeoInput)[] =>
    urlWaypoints.map((urlWaypoint) => fromURLWaypoint(urlWaypoint));

const fromURLObject = (routeURLObject): ItineraryPlanningRequest => ({
    ...omit(routeURLObject, "sorted", "unsorted"),
    sortedGeoInputs: fromURLWaypoints(routeURLObject.sorted),
    ...(routeURLObject.unsorted && { unsortedGeoInputs: fromURLWaypoints(routeURLObject.unsorted) })
});

export const fromURLPlanningCriteria = (routeURLParam): ItineraryPlanningRequest =>
    fromURLObject(juriInstance.decode(decodeURIComponent(routeURLParam)));
