import { extractFeetAndInchesMeasureInInches, formatFeetAndInches } from "./units";
import Qty from "js-quantities";
import { ElectricVehicleConsumptionModel, HazmatOption, UnitsType, VehicleParameters } from "@anw/gor-sdk";
import { ElectricVehicleConsumptionSettings, VehicleSettings } from "../state/tree/map-page/settings/reducers";
import { EngineTypeValue } from "../constants/EvConstants";
import { chargeInPercentageTokWh } from "./chargingInformation";

export const MAX_LENGTH_IN_METERS = 18.75;
export const MAX_LENGTH_IN_INCHES = 61 * 12 + 6;
export const MAX_WIDTH_IN_METERS = 2.6;
export const MAX_WIDTH_IN_INCHES = 8 * 12 + 6;
export const MAX_HEIGHT_IN_METERS = 4.95;
export const MAX_HEIGHT_IN_INCHES = 16 * 12 + 3;
export const MAX_WEIGHT_IN_TONS = 44;
export const MAX_WEIGHT_IN_LBS = 97003;
export const MAX_AXLE_WEIGHT_IN_TONS = 11.5;
export const MAX_AXLE_WEIGHT_IN_LBS = 25353;
export const MAX_SPEED_IN_KMH = 100;
export const MAX_SPEED_IN_MPH = 100;
const INVALID = "Invalid value";

/**
 * @see VehicleSettings For matching property names.
 */
export enum VehicleSettingsMeasure {
    LENGTH = "length",
    WIDTH = "width",
    HEIGHT = "height",
    WEIGHT = "weight",
    AXLE_WEIGHT = "axleWeight",
    MAX_SPEED = "maxSpeed"
}

/**
 * Map of max values for vehicle measures, values are [VehicleMeasure, [METRIC, US]].
 */
export const mapOfMaxValuesForVehicleMeasures = new Map<VehicleSettingsMeasure, number[]>([
    [VehicleSettingsMeasure.LENGTH, [MAX_LENGTH_IN_METERS, MAX_LENGTH_IN_INCHES]],
    [VehicleSettingsMeasure.WIDTH, [MAX_WIDTH_IN_METERS, MAX_WIDTH_IN_INCHES]],
    [VehicleSettingsMeasure.HEIGHT, [MAX_HEIGHT_IN_METERS, MAX_HEIGHT_IN_INCHES]],
    [VehicleSettingsMeasure.WEIGHT, [MAX_WEIGHT_IN_TONS, MAX_WEIGHT_IN_LBS]],
    [VehicleSettingsMeasure.AXLE_WEIGHT, [MAX_AXLE_WEIGHT_IN_TONS, MAX_AXLE_WEIGHT_IN_LBS]],
    [VehicleSettingsMeasure.MAX_SPEED, [MAX_SPEED_IN_KMH, MAX_SPEED_IN_MPH]]
]);

/**
 * Array of vehicle measures.
 */
export const vehicleMeasureValues: VehicleSettingsMeasure[] = Object.keys(VehicleSettingsMeasure).map(
    (k) => VehicleSettingsMeasure[k]
);

/**
 * Checks if the measure is for size, where we need to handle feet and inches.
 * @param measure
 */
export function isSizeMeasure(measure: VehicleSettingsMeasure) {
    return (
        measure === VehicleSettingsMeasure.LENGTH ||
        measure === VehicleSettingsMeasure.WIDTH ||
        measure === VehicleSettingsMeasure.HEIGHT
    );
}

function getQuantity(unitsType: UnitsType, measure: VehicleSettingsMeasure, value: string): Qty {
    if (unitsType === "IMPERIAL_US" && isSizeMeasure(measure)) {
        return extractFeetAndInchesMeasureInInches(formatFeetAndInches(value));
    } else {
        return Qty.parse(value);
    }
}

/**
 * Validating if the measure is correctly entered.
 * @param measure type of measure for which we want to validate.
 * @param value entered by user.
 * @param unitsType user selected units type.
 * @return validation error in case the value is invalid and null if everything is fine.
 */
export const isVehicleMeasurementValid = (
    measure: VehicleSettingsMeasure,
    value: string,
    unitsType: UnitsType
): string => {
    if (value) {
        const valueQty = getQuantity(unitsType, measure, value);
        if (valueQty) {
            if (
                unitsType === "METRIC" ||
                (unitsType === "IMPERIAL_UK" && measure !== VehicleSettingsMeasure.MAX_SPEED)
            ) {
                return valueQty.scalar > 0 && valueQty.scalar <= mapOfMaxValuesForVehicleMeasures.get(measure)[0]
                    ? null
                    : INVALID;
            } else {
                return valueQty.scalar > 0 && valueQty.scalar <= mapOfMaxValuesForVehicleMeasures.get(measure)[1]
                    ? null
                    : INVALID;
            }
        }
        return INVALID;
    }
    return null;
};

/**
 * Transforms the value from one units type system to another.
 * @param unitsTypeFrom
 * @param unitsTypeTo
 * @param measure
 * @param value
 */
export const transformUnitsType = (
    unitsTypeFrom: UnitsType,
    unitsTypeTo: UnitsType,
    measure: VehicleSettingsMeasure,
    value: string
): string => {
    if (!value || unitsTypeFrom === unitsTypeTo) {
        // nothing to do
        return value;
    }
    if (isSizeMeasure(measure)) {
        if (unitsTypeFrom === "IMPERIAL_US") {
            // we know we are transferring the same to both Metric and UK
            const qty = extractFeetAndInchesMeasureInInches(value);
            return qty.to("m").toPrec("cm").scalar.toString();
        } else if (unitsTypeTo === "IMPERIAL_US") {
            // we know we are transferring the same from both Metric and UK
            return formatFeetAndInches(
                Qty.parse(value + "m")
                    .to("feet")
                    .toPrec("inch")
                    .scalar.toString()
            );
        } else {
            return value;
        }
    } else if (measure === VehicleSettingsMeasure.MAX_SPEED) {
        if (unitsTypeFrom === "METRIC") {
            // we know we are transferring the same to both US and UK
            return Qty.parse(value + "kph")
                .to("mph")
                .toPrec("mph")
                .scalar.toString();
        } else if (unitsTypeTo === "METRIC") {
            // we know we are transferring the same from both US and UK
            return Qty.parse(value + "mph")
                .to("kph")
                .toPrec("kph")
                .scalar.toString();
        } else {
            return value;
        }
    } else {
        if (unitsTypeFrom === "IMPERIAL_US") {
            // we know we are transferring the same to both Metric and UK
            return Qty.parse(value + "lbs")
                .to("tonne")
                .toPrec("kg")
                .scalar.toString();
        } else if (unitsTypeTo === "IMPERIAL_US") {
            // we know we are transferring the same from both Metric and UK
            return Qty.parse(value + "tonne")
                .to("lbs")
                .toPrec("lbs")
                .scalar.toString();
        } else {
            return value;
        }
    }
};

/**
 * To help with calculating the number to send with route planning.
 * @param unitsType of the vehicle measures
 * @param measure which we want to calculate
 * @param value to be calculated
 */
export const toPlannerMeasure = (unitsType: UnitsType, measure: VehicleSettingsMeasure, value: string): number => {
    const convertedValue = transformUnitsType(unitsType, "METRIC", measure, value);
    if (!convertedValue) {
        return null;
    }
    if (measure === VehicleSettingsMeasure.WEIGHT || measure === VehicleSettingsMeasure.AXLE_WEIGHT) {
        // convert to kilograms
        return Number(convertedValue) * 1000;
    } else {
        // just copy values
        return Number(convertedValue);
    }
};

export const toPlannerEvConsumptionModel = (
    settings: ElectricVehicleConsumptionSettings
): ElectricVehicleConsumptionModel => {
    if (settings) {
        return {
            constantSpeedConsumptionInkWhPerHundredkm: settings.constantSpeedConsumptionInkWhPerHundredkm,
            currentChargeInkWh: chargeInPercentageTokWh(settings.currentChargeInPercentage, settings.maxChargeInkWh),
            maxChargeInkWh: settings.maxChargeInkWh,
            minChargeAtDestinationInkWh: chargeInPercentageTokWh(
                settings.minChargeAtDestinationInPercentage,
                settings.maxChargeInkWh
            ),
            minChargeAtChargingStopsInkWh: chargeInPercentageTokWh(
                settings.minChargeAtChargingStopsInPercentage,
                settings.maxChargeInkWh
            ),
            auxiliaryPowerInkW: settings.auxiliaryPowerInkW,
            chargeMarginsInkWh: settings.chargeMarginsInkWh,
            consumptionInkWhPerkmAltitudeGain: settings.consumptionInkWhPerkmAltitudeGain,
            recuperationInkWhPerkmAltitudeLoss: settings.recuperationInkWhPerkmAltitudeLoss,
            vehicleWeight: settings.vehicleWeight,
            accelerationEfficiency: settings.accelerationEfficiency,
            decelerationEfficiency: settings.decelerationEfficiency,
            uphillEfficiency: settings.uphillEfficiency,
            downhillEfficiency: settings.downhillEfficiency
        } as ElectricVehicleConsumptionModel;
    }
};

export const toPlannerVehicleModelEvConsumptionModel = (
    settings: ElectricVehicleConsumptionSettings
): ElectricVehicleConsumptionModel => {
    return {
        currentChargeInkWh: chargeInPercentageTokWh(settings.currentChargeInPercentage, settings.maxChargeInkWh),
        minChargeAtDestinationInkWh: chargeInPercentageTokWh(
            settings.minChargeAtDestinationInPercentage,
            settings.maxChargeInkWh
        ),
        minChargeAtChargingStopsInkWh: chargeInPercentageTokWh(
            settings.minChargeAtChargingStopsInPercentage,
            settings.maxChargeInkWh
        )
    } as ElectricVehicleConsumptionModel;
};

export const toPlanningVehicleParameters = (settings: VehicleSettings): VehicleParameters => {
    if (settings.vehicleModelId) {
        const vehicleParameters = {
            vehicleModelId: settings.vehicleModelId,
            chargingParameters: {}
        } as VehicleParameters;
        if (settings.engineType) {
            vehicleParameters.engineType = settings.engineType;
        }
        if (settings.electricVehicleConsumptionSettings) {
            vehicleParameters.electricVehicleConsumptionModel = toPlannerVehicleModelEvConsumptionModel(
                settings.electricVehicleConsumptionSettings
            );
        }
        return vehicleParameters;
    }
    const vehicleParameters = {
        length: toPlannerMeasure(settings.unitsType, VehicleSettingsMeasure.LENGTH, settings.length),
        width: toPlannerMeasure(settings.unitsType, VehicleSettingsMeasure.WIDTH, settings.width),
        height: toPlannerMeasure(settings.unitsType, VehicleSettingsMeasure.HEIGHT, settings.height),
        weight: toPlannerMeasure(settings.unitsType, VehicleSettingsMeasure.WEIGHT, settings.weight),
        axleWeight: toPlannerMeasure(settings.unitsType, VehicleSettingsMeasure.AXLE_WEIGHT, settings.axleWeight),
        maxSpeed: toPlannerMeasure(settings.unitsType, VehicleSettingsMeasure.MAX_SPEED, settings.maxSpeed),
        hazmatOptions: settings.hazmatOptions,
        adrTunnelRestrictionCode:
            settings.adrTunnelRestrictionCode == "none" ? undefined : settings.adrTunnelRestrictionCode,
        engineType: settings.engineType
    } as VehicleParameters;

    if (settings.engineType === EngineTypeValue.Electric) {
        vehicleParameters.electricVehicleConsumptionModel = toPlannerEvConsumptionModel(
            settings.electricVehicleConsumptionSettings
        );

        const selectedMSPs = settings.mspSettings?.filter((msp) => msp.enabled === true).map((msp) => msp.id);

        vehicleParameters.chargingParameters = {
            chargingParameters: settings.chargingParameters,
            preferredMSPs: selectedMSPs
        };
    } else {
        vehicleParameters.electricVehicleConsumptionModel && delete vehicleParameters.electricVehicleConsumptionModel;
    }
    return vehicleParameters;
};

export const planningToOriginalVehicleParameters = (plannerURLMeasures): VehicleParameters => {
    const vehicleParameters = { hazmatOptionsSelection: [] };

    for (const [key, value] of Object.entries(plannerURLMeasures || {})) {
        if (value === null) {
            vehicleParameters[key] = "";
        } else {
            if (key === "weight" || key === "axleWeight") {
                vehicleParameters[key] = String((value as number) / 1000);
            } else if (key === "adrTunnelRestrictionCode" || key === "hazmatOptions") {
                vehicleParameters[key] = value as [];
            } else {
                vehicleParameters[key] = String(value);
            }
        }
    }

    // loop through hazmatOptions array and set the respective index in hazmatOptionsSelection as true or false based on the value
    const hazmatOptions = plannerURLMeasures.hazmatOptions;
    if (hazmatOptions) {
        const hazmatOptionsArray = [
            "EU_EXPLOSIVE_MATERIALS",
            "EU_GENERAL_HAZARDOUS_MATERIALS",
            "EU_GOODS_HARMFUL_TO_WATER",
            "US_CLASS_1_EXPLOSIVES",
            "US_CLASS_2_GASES",
            "US_CLASS_3_FLAMMABLE_LIQUIDS",
            "US_CLASS_4_FLAMMABLE_SOLIDS",
            "US_CLASS_5_OXIDIZING_AGENTS_AND_ORGANIC_PEROXIDES",
            "US_CLASS_6_TOXIC_AND_INFECTIOUS_SUBSTANCES",
            "US_CLASS_7_RADIOACTIVE_SUBSTANCES",
            "US_CLASS_8_CORROSIVE_SUBSTANCES",
            "US_CLASS_9_MISCELLANEOUS"
        ];
        for (let i = 0; i < hazmatOptionsArray.length; i++) {
            const hazmatOption = hazmatOptionsArray[i];
            vehicleParameters.hazmatOptionsSelection[i] = hazmatOptions.includes(hazmatOption as HazmatOption);
        }
    }
    return vehicleParameters as VehicleParameters;
};
