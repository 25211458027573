import capitalize from "lodash/capitalize";
import Mark from "mark.js";
import { LngLatBounds, LngLatLike } from "maplibre-gl";

import { AutocompleteSegment } from "../state/tree/map-page/search/autocompleteAPITypes";
import { MatchedAutoCompleteSearch, SearchIntention } from "../state/tree/map-page/search/types";
import { Searchable, TTMSearchableResult, TTMSearchResult } from "./locationTypes";
import { calcMatchScore, toSearchSimplifiedText } from "./myPlaces";
import { buildLocationInfoTitle } from "./location";
import { isAutocompleteType, isSearchType, ResultType } from "../services/ngs/ngsAPITypes";

export const searchAutoCompleteToTitle = (result: AutocompleteSegment): string => {
    return `${result.value} ${capitalize(result.type)}`;
};

export const detectRecoveryVsDiscovery = (results: Partial<TTMSearchResult>[]): MatchedAutoCompleteSearch => {
    let searchIntention: SearchIntention = SearchIntention.RECOVERY;
    let matchedCategory = "";
    let matchedBrand = "";
    let matchedTextValue = "";
    // logic was implemented based on the suggestion from the comment below:
    // https://confluence.tomtomgroup.com/display/SearchPU/TTM+recovery+vs+discovery+indicator?focusedCommentId=863871503#comment-863871503
    const firstSearchResult = results.find((result) => isSearchType(result.ngsType));
    const autocompleteResults = results.filter((result) => isAutocompleteType(result.ngsType));
    let matchedResult = undefined;
    if (firstSearchResult?.ngsType === ResultType.POI) {
        autocompleteResults.some((result) => {
            let categoryMatches = false;
            // if a brand is matched in autocomplete result, check if the first fuzzy search result brand matches the autocomplete brand
            const brandMatches =
                result.ngsType == ResultType.BRAND &&
                firstSearchResult.name?.toLowerCase().includes(result.name.toLowerCase());
            if (brandMatches) {
                matchedBrand = result.name;
                matchedTextValue = result.name;
                searchIntention = SearchIntention.DISCOVERY;
                matchedResult = result;
                return true;
            } else {
                // if a category is matched in autocomplete result, check if the first fuzzy search result category matches the autocomplete category
                // checking only the first 4 digits of category id as this represent the general category (by observing results!)
                categoryMatches =
                    result.ngsType == ResultType.CATEGORY &&
                    firstSearchResult.poiCategorySetIDs?.some(
                        (cat) => result.externalID.indexOf(cat.toString().slice(0, 4)) === 0
                    );
                // if the brand or the category from the autocomplete matches the one in the 1st search result, then it is a "discovery"
                if (categoryMatches) {
                    // save main category id to include results that contain any childCategoryIds
                    matchedCategory = result.externalID.slice(0, 4);
                    matchedTextValue = result.name;
                    searchIntention = SearchIntention.DISCOVERY;
                    matchedResult = result;
                    return true;
                }
            }
        });
    }

    return { searchIntention, matchedBrand, matchedCategory, matchedTextValue, result: matchedResult };
};

export const checkIfResultsOutsideViewport = (
    searchResults: TTMSearchResult[],
    matchedAutoCompleteSearch: MatchedAutoCompleteSearch,
    mapBBox: [[number, number], [number, number]]
): boolean => {
    const { searchIntention, matchedBrand, matchedCategory } = matchedAutoCompleteSearch;
    let filteredSearchResults = searchResults;
    let areResultsOutsideViewport = false;
    if (mapBBox && searchIntention == SearchIntention.DISCOVERY) {
        if (matchedAutoCompleteSearch.matchedBrand) {
            filteredSearchResults = searchResults.filter((result) =>
                result.name?.toLowerCase().includes(matchedBrand.toLowerCase())
            );
        } else {
            filteredSearchResults = searchResults.filter((result) =>
                result.poiCategorySetIDs?.some((cat) => cat.toString().indexOf(matchedCategory) === 0)
            );
        }
        const bboxInstance = new LngLatBounds(mapBBox[0], mapBBox[1]);
        // determine if ANY fuzzy search results are outside of viewport
        areResultsOutsideViewport = filteredSearchResults.some(
            (result) => !bboxInstance.contains([result.point[1], result.point[0]] as LngLatLike)
        );
    }
    return areResultsOutsideViewport;
};

export const ngsCheckIfResultsOutsideViewport = (
    suggestViewport: [[number, number], [number, number]],
    matchedAutoCompleteSearch: MatchedAutoCompleteSearch,
    mapBBox: [[number, number], [number, number]]
): boolean => {
    const { searchIntention } = matchedAutoCompleteSearch;
    let areResultsOutsideViewport = false;
    if (mapBBox && searchIntention == SearchIntention.DISCOVERY) {
        const bboxInstance = new LngLatBounds(mapBBox[0], mapBBox[1]);
        areResultsOutsideViewport = suggestViewport.some(
            (result) => !bboxInstance.contains([result[1], result[0]] as LngLatLike)
        );
    }
    return areResultsOutsideViewport;
};

export const highlightSearchResults = (searchString: string) => {
    const markInstance = new Mark(document.querySelector(".search-results"));
    markInstance.unmark({
        done: () => {
            markInstance.mark(searchString, {
                exclude: ["div.list-two-line-text__bottom-right"]
            });
        }
    });
};

/**
 * Returns the items whose search texts (prepared names) match with the given query, sorted by match score, descending.
 * @param searchableItems The locations to search against. IMPORTANT: their searchText fields should have been prefilled.
 * @param query The search query. It does not need to come pre-simplified.
 */
export const searchItems = <T extends Searchable>(searchableItems: T[], query: string): T[] => {
    const preparedQuery = toSearchSimplifiedText(query);
    return searchableItems
        ?.map((item) => ({
            ...item,
            matchScore: calcMatchScore(preparedQuery, item.searchText)
        }))
        .filter((searchedLocation) => searchedLocation.matchScore > 0.05)
        .sort((a, b) => (a.matchScore > b.matchScore ? -1 : 1));
};

export const withSearchText = (searchResult: TTMSearchResult): TTMSearchableResult => ({
    ...searchResult,
    searchText: toSearchSimplifiedText(buildLocationInfoTitle(searchResult))
});

export const withSearchTexts = (searchResults: TTMSearchResult[]): TTMSearchableResult[] =>
    searchResults && searchResults.map((searchResult) => withSearchText(searchResult));
