import { MapGeoJSONFeature } from "maplibre-gl";
import { TrafficIncidentTEC } from "@anw/gor-sdk";
import { IncidentDetailsInfo } from "../components/map/IncidentDetailsPopup";
import { TFunction } from "i18next";

const magnitudeFromString = (magnitude): number => {
    if (magnitude === null || magnitude === undefined) {
        return 0;
    }
    if (!isNaN(magnitude)) {
        return magnitude;
    }
    switch (magnitude.toLowerCase()) {
        case "minor":
            return 1;
        case "moderate":
            return 2;
        case "major":
            return 3;
        case "undefined":
            return 4;
        default:
            return 0;
    }
};

export const getTrafficPopupDetailsAlongTheRoute = (feature: MapGeoJSONFeature, t: TFunction): IncidentDetailsInfo => {
    // numeric icon codes following up the icon_category_x codes from generic map incidents
    const mapIconCategories = [];
    const descriptions = [];
    const magnitudeNumber = magnitudeFromString(feature.properties.magnitudeOfDelay);
    const tec: TrafficIncidentTEC = JSON.parse(feature.properties.tec);
    tec.causes?.forEach((cause) => {
        switch (cause.mainCauseCode) {
            case 1:
                mapIconCategories.push(6);
                if (magnitudeNumber > 0 && magnitudeNumber < 4) {
                    descriptions.push(t(`Traffic:jam.description_${magnitudeNumber}`));
                } else {
                    descriptions.push(t("Traffic:incident_6"));
                }
                break;
            case 2:
                mapIconCategories.push(1);
                descriptions.push(t("Traffic:incident_1"));
                break;
            case 3:
                mapIconCategories.push(9);
                descriptions.push(t("Traffic:incident_9"));
                break;
            case 5:
                mapIconCategories.push(8);
                descriptions.push(t("Traffic:incident_8"));
                break;
            case 6:
                mapIconCategories.push(5);
                descriptions.push(t("Traffic:incident_5"));
                break;
            case 9:
                mapIconCategories.push(3);
                descriptions.push(t("Traffic:incident_3"));
                break;
            case 13:
                mapIconCategories.push(14);
                descriptions.push(t("Traffic:incident_14"));
                break;
            case 18:
                mapIconCategories.push(2);
                descriptions.push(t("Traffic:incident_2"));
                break;
            default:
                mapIconCategories.push(15);
                descriptions.push(t(`Traffic:tec_code_${cause.mainCauseCode}`));
                break;
        }
    });
    return {
        icons: mapIconCategories,
        descriptions,
        delaySeconds: feature.properties.delayInSeconds,
        magnitude: magnitudeNumber
    };
};

export const getTrafficPopupDetailsForIncidents = (feature: MapGeoJSONFeature): IncidentDetailsInfo => {
    const icons = [];
    const descriptions = [];
    let continueReadingFields = true;
    let index = 0;
    while (continueReadingFields) {
        const icon: number = feature.properties[`icon_category_${index}`];
        const description: string = feature.properties[`description_${index}`];
        if (icon !== null && icon !== undefined && description) {
            icons.push(icon);
            descriptions.push(description);
            index++;
        } else {
            continueReadingFields = false;
        }
    }
    // because the map icons round the delay up we have to do it as well here
    const delayInMinutes = feature.properties["delay"] / 60;
    const durationRoundUpInMinutes =
        Math.floor(delayInMinutes) < delayInMinutes ? Math.floor(delayInMinutes) + 1 : Math.floor(delayInMinutes);
    return {
        icons,
        descriptions,
        delaySeconds: durationRoundUpInMinutes * 60,
        magnitude: feature.properties["magnitude"],
        endDate: feature.properties["end_date"] ? new Date(feature.properties["end_date"]) : undefined
    };
};
