import { Feature, MultiPolygon } from "geojson";
import { additionalData } from "@tomtom-international/web-sdk-services/esm";
import difference from "@turf/difference";
import bboxPolygon from "@turf/bbox-polygon";
import memoize from "lodash/memoize";

const fetchInvertedGeometryRequest = async (
    geometryID: string,
    zoom: number,
    apiKey: string
): Promise<Feature<MultiPolygon>> => {
    const additionalDataResponse = await additionalData({
        geometries: [geometryID],
        geometriesZoom: zoom,
        key: apiKey
    });
    try {
        return additionalDataResponse?.additionalData?.[0].geometryData
            ? (difference(
                  bboxPolygon([-180, 90, 180, -90]), // pretty much the inhabited world
                  additionalDataResponse.additionalData[0].geometryData.features?.[0] // we only requested one travel time
              ) as Feature<MultiPolygon>)
            : null;
    } catch (error) {
        // For huge polygons, the "difference" operation might crash:
        console.warn(error?.message ?? error);
        return null;
    }
};

export const fetchInvertedGeometry = memoize(fetchInvertedGeometryRequest, (...args) => JSON.stringify(args));
