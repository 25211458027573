import { isBrowser } from "./agent";
import { changeStyleQuickProps, MapStyleEditInput } from "./mapStyle";
import MapProvider from "../classes/map/MapProvider";

export class ExposedApi {
    private static exposedName = "exposedApi";
    private readonly global: unknown;
    private _store = null;
    private isMapInitTriggered = false;
    private isAppInteractive = false;

    constructor() {
        this.global = isBrowser() ? window : global;

        if (!this.global[ExposedApi.exposedName]) {
            Object.assign(this.global, {
                [ExposedApi.exposedName]: this
            });

            return this;
        }

        return this.global[ExposedApi.exposedName];
    }

    isMapStyleLoaded() {
        const styleLoaded = MapProvider.map?.isStyleLoaded();
        console.log(`ExposedApi.isStyleLoaded(): ${styleLoaded}`);
        return styleLoaded;
    }

    isMapFullyLoaded() {
        const loaded = MapProvider.map?.loaded();
        console.log(`ExposedApi.isMapFullyLoaded(): ${loaded}`);
        return loaded;
    }

    isMapMoving() {
        const moving = MapProvider.map?.isMoving();
        console.log(`ExposedApi.isMapMoving(): ${moving}`);
        return moving;
    }

    changeStyleQuickProps(props: MapStyleEditInput): void {
        changeStyleQuickProps(props);
    }

    async clearUserData() {
        const { onClearData } = await import("../state/tree/map-page/settings/userActionThunks");
        return this.store.dispatch(onClearData());
    }

    set mapInitTriggered(isMapInitTriggered: boolean) {
        this.isMapInitTriggered = isMapInitTriggered;
    }

    set appInteractive(value: boolean) {
        this.isAppInteractive = value;
    }

    get appInteractive() {
        console.log(`get ExposedApi.appInteractive: ${this.isAppInteractive}`);
        return this.isAppInteractive;
    }

    get map() {
        if (!MapProvider.map) {
            console.error("Map object is not set");
        }

        return MapProvider.map;
    }

    set store(store) {
        this._store = store;
    }

    get store() {
        if (!this._store) {
            console.error("Store object is not set yet");
        }

        return this._store;
    }

    destroy() {
        this._store = null;
        delete this.global[ExposedApi.exposedName];
    }
}
