import {
    Address,
    EntryPoint as SDKEntryPoint,
    FuzzySearchResult,
    SearchResultType
} from "@tomtom-international/web-sdk-services";
import {
    ActiveItinerary,
    EntryPoint,
    EntryPointType,
    GeographyEntityType,
    LocationInfo,
    LocationProvider,
    LocationType,
    Point as GORPoint,
    PointBoundingBox
} from "@anw/gor-sdk";
import { Feature, Point } from "geojson";
import { LngLat, LngLatBounds } from "maplibre-gl";
import { nanoid } from "@reduxjs/toolkit";
import truncate from "lodash/truncate";
import omit from "lodash/omit";
import capitalize from "lodash/capitalize";
import snakeCase from "lodash/snakeCase";

import { poiClassificationToIconID } from "./poiClassificationsMapping";
import {
    Heading,
    TTMLocation,
    TTMLocationContext,
    TTMLocationInfo,
    TTMSearchResult,
    TTMUserMapLocation,
    TTMWaypoint
} from "./locationTypes";
import { getDestination } from "./itinerary";
import { removeEmptyParameters } from "./objects";
import { DetailsResponse, ResultType } from "../../src/services/ngs/ngsAPITypes";

import i18next from "i18next";

export const getLocationInfo = (location: TTMLocation): TTMLocationInfo => {
    // TODO we're missing lat lon  in location here to calculate a distance
    if (!location) {
        return null;
    }
    if (location.context === TTMLocationContext.WAYPOINT) {
        return (location as TTMWaypoint).locationInfo;
    } else if (location.context === TTMLocationContext.MY_PLACES) {
        return (location as TTMUserMapLocation).mapLocation?.locationInfo;
    }
    return omit(location, "context") as TTMLocationInfo;
};

export const copyWithLocationInfo = (location: TTMLocation, locationInfo: TTMLocationInfo): TTMLocation => {
    if (location) {
        if (location.context === TTMLocationContext.WAYPOINT) {
            return { ...location, locationInfo } as TTMLocation;
        } else if (location.context === TTMLocationContext.MY_PLACES) {
            return { ...location, mapLocation: { ...(location as TTMUserMapLocation).mapLocation, locationInfo } };
        } else {
            return { ...location, ...locationInfo } as TTMLocation;
        }
    }
    return location;
};

export const getLocationInfoFromActiveItinerary = (activeItinerary: ActiveItinerary): LocationInfo => {
    if (!activeItinerary) {
        return null;
    }
    return activeItinerary.destination?.locationInfo || getDestination(activeItinerary.itinerary)?.locationInfo;
};

/**
 * Used to represent the search locationInfo in a one-liner title
 *
 * @param locationInfo
 */
const buildGenericLocationInfoTitle = (locationInfo: TTMLocationInfo): string =>
    locationInfo.poiName ||
    locationInfo.formattedAddress ||
    (locationInfo.point ? locationInfo.point[0]?.toString() + ", " + locationInfo.point[1]?.toString() : "");

export const buildLocationInfoTitle = (locationInfo: TTMLocationInfo): string => {
    if (!locationInfo) {
        return "";
    }
    if (locationInfo.customName) {
        return truncate(locationInfo.customName, { length: 30 });
    } else {
        return buildGenericLocationInfoTitle(locationInfo);
    }
};

export const buildLocationTitle = (location: TTMLocation): string => buildLocationInfoTitle(getLocationInfo(location));

const toDisplayPOICategories = (
    locationInfo: TTMLocationInfo
): string => {
    return locationInfo.localizedPOIClassificationNames.map((name) => capitalize(name)).join(", ");
};

const buildAppendedCountry = (text: string, country: string): string =>
    !country || text.endsWith(country) ? "" : ", " + country;

const buildHeadingForGeocoder = (locationInfo: TTMLocationInfo): Heading => {
    let title = "";
    let subtitle = "";
    const freeformAddress = locationInfo.formattedAddress;
    const country = locationInfo.localizedCountryName;
    const indexOfFirstComma = freeformAddress.indexOf(",");
    if (indexOfFirstComma > 0) {
        title = freeformAddress.substring(0, indexOfFirstComma);
        subtitle =
            freeformAddress.substring(indexOfFirstComma + 1).trimStart() +
            buildAppendedCountry(freeformAddress, country);
    } else {
        title = freeformAddress;
        subtitle = country && title != country ? country : "";
    }
    return { title, subtitle };
};

/**
 * Used to represent the search result in a two-liner heading.
 *
 * @param locationInfo
 * @param options addressSubtitle: poi subtitle contains the address rather than category.
 */
export const buildNgsLocationInfoHeading = (
    locationInfo: TTMLocationInfo,
    options: { addressSubtitle?: boolean } = {}
): Heading => {
    if (!locationInfo) {
        return { title: "", subtitle: "" };
    }

    let title = locationInfo.customName || locationInfo.name;
    let subtitle =
        (locationInfo.customName || locationInfo.poiName) &&
        !options?.addressSubtitle &&
        locationInfo.localizedPOIClassificationNames
            ? toDisplayPOICategories(locationInfo)
            : locationInfo.customName
            ? subtitleForMyItems(locationInfo)
            : locationInfo.description;

    if (!title && !subtitle && locationInfo.formattedAddress) {
        return buildHeadingForGeocoder(locationInfo);
    }

    title = title || "";
    subtitle = subtitle || "";
    return { title, subtitle };
};

const subtitleForMyItems = (
    locationInfo: TTMLocationInfo
): string => {
    if (locationInfo.name && locationInfo.description) {
        return locationInfo.name + ", " + locationInfo.description;
    }
    if (locationInfo.formattedAddress) {
        return locationInfo.formattedAddress;
    }
    return undefined;
}

export const buildNgsHeading = (location: TTMLocation, options: { addressSubtitle?: boolean } = {}): Heading =>
    buildNgsLocationInfoHeading(getLocationInfo(location), options);

export const locationInfoToFeature = (locationInfo: TTMLocationInfo, index: number): Feature<Point> => {
    return {
        type: "Feature",
        properties: {
            // ("id" property helps UserEventsHandler compare features)
            id: locationInfo.externalID,
            title: buildLocationInfoTitle(locationInfo),
            iconID: poiClassificationToIconID[locationInfo?.poiCategory] || "default",
            index
        },
        geometry: {
            type: "Point",
            coordinates: [locationInfo.point[1], locationInfo.point[0]]
        }
    };
};

export const userMapLocationToFeature = (userMapLocation: TTMUserMapLocation, index: number): Feature<Point> => {
    if (userMapLocation.mapLocation?.locationInfo) {
        return locationInfoToFeature(userMapLocation.mapLocation.locationInfo, index);
    }
    // (should normally not occur, since locationInfo's should ideally come resolved from back-end)
    return {
        type: "Feature",
        properties: {
            id: userMapLocation.id,
            title: "Unnamed"
        },
        geometry: {
            type: "Point",
            coordinates: [userMapLocation.mapLocation.pointLatLon[1], userMapLocation.mapLocation.pointLatLon[0]]
        }
    };
};

export const lngLatToFeature = (lngLat: LngLat): Feature<Point> => {
    return {
        type: "Feature",
        properties: {},
        geometry: {
            type: "Point",
            coordinates: [lngLat.lng, lngLat.lat]
        }
    };
};

const toConstantCase = (str: string): string => snakeCase(str).toUpperCase();

const toLocationInfoType = (sdkType: SearchResultType): LocationType =>
    sdkType === "Point Address" ? "ADDRESS" : (toConstantCase(sdkType) as LocationType);

const resultTypeToLocationInfoType = (type: ResultType): LocationType =>
    type === "POINT_ADDRESS" ? "ADDRESS" : (type as LocationType);

const toEntryPoints = (sdkEntryPoints: SDKEntryPoint[]): EntryPoint[] =>
    sdkEntryPoints?.map((sdkEntryPoint) => ({
        type: toConstantCase(sdkEntryPoint.type) as EntryPointType,
        functions: sdkEntryPoint.functions,
        position: [sdkEntryPoint.position.lat, sdkEntryPoint.position.lng]
    }));

export const detailsToTTMSearchResult = (searchResult: DetailsResponse): TTMSearchResult => {
    if (!searchResult) {
        return undefined;
    }
    const address = searchResult.address;
    const mappedResult = {
        // GOR-type parts:
        formattedAddress: address.freeformAddress,
        poiName: searchResult.type === ResultType.POI ? searchResult.name : undefined,
        externalID: searchResult.id,
        point: [searchResult.geometries.position.lat, searchResult.geometries.position.lon] as GORPoint,
        provider: "TOM_TOM" as LocationProvider,
        type: resultTypeToLocationInfoType(searchResult.type),
        poiCategory: searchResult?.categories?.[0].code,
        poiCategorySetIDs: searchResult?.categories?.map((cat) => cat.id),
        localizedPOIClassificationNames: searchResult?.categories?.[0].names.map((name) => name.name),
        poiSubcategories: undefined,
        poiBrandNames: searchResult?.brands?.map((brand) => brand.name),
        street: address.streetName,
        houseNumber: address.streetNumber,
        postCode: address.postalCode,
        city: address.municipality,
        countrySubdivision: address.countrySubdivision,
        boundingBox: searchResult?.geometries.boundingBox
            ? ({
                  southWest: [
                      searchResult.geometries.boundingBox.bottomRightPosition.lat,
                      searchResult.geometries.boundingBox.topLeftPosition.lon
                  ],
                  northEast: [
                      searchResult.geometries.boundingBox.topLeftPosition.lat,
                      searchResult.geometries.boundingBox.bottomRightPosition.lon
                  ]
              } as PointBoundingBox)
            : undefined,
        countryISO3: address.countryCodeISO3,
        poiPhone: searchResult?.contact?.phone,
        poiUrl: searchResult?.contact?.url,
        municipality: address.municipality,
        municipalitySubdivision: address.municipalitySubdivision,
        countrySecondarySubdivision: address.countrySecondarySubdivision,
        countryTertiarySubdivision: address.countryTertiarySubdivision,
        extendedPostalCode: address.extendedPostalCode,
        countrySubdivisionName: address.countrySubdivisionName,
        geometryId: searchResult.dataSources?.geometry?.id,

        // TTM-only location-info parts:
        localizedCountryName: searchResult.address.country,
        openingHours: searchResult.openingHours,
        timeZone: searchResult.timeZone,
        ngsType: searchResult.type,
        name: searchResult.name,
        description: searchResult.description,
        link: undefined
    };
    removeEmptyParameters(mappedResult);
    return mappedResult;
};

export const toTTMSearchResult = (searchResult: FuzzySearchResult): TTMSearchResult => {
    if (!searchResult) {
        return undefined;
    }
    const address = searchResult.address;
    const poi = searchResult.poi;
    const mappedResult = {
        // GOR-type parts:
        formattedAddress: address.freeformAddress,
        poiName: poi?.name,
        externalID: searchResult.id,
        point: [searchResult.position.lat, searchResult.position.lng] as GORPoint,
        provider: "TOM_TOM" as LocationProvider,
        type: toLocationInfoType(searchResult.type),
        poiCategory: poi?.classifications?.[0].code,
        poiSubcategories: poi?.classifications
            ?.slice(1, poi.classifications.length)
            ?.map((classification) => classification.code),
        poiCategorySetIDs: poi?.categorySet?.map((cat) => cat.id),
        poiBrandNames: poi?.brands?.map((brand) => brand.name),
        street: address.streetName,
        houseNumber: address.streetNumber,
        postCode: address.postalCode,
        city: address.municipality,
        countrySubdivision: address.countrySubdivision,
        countryISO3: address.countryCodeISO3,
        boundingBox: searchResult.boundingBox
            ? ({
                  southWest: [searchResult.boundingBox.btmRightPoint.lat, searchResult.boundingBox.topLeftPoint.lng],
                  northEast: [searchResult.boundingBox.topLeftPoint.lat, searchResult.boundingBox.btmRightPoint.lng]
              } as PointBoundingBox)
            : undefined,
        poiPhone: poi?.phone,
        poiUrl: poi?.url,
        entryPoints: toEntryPoints(searchResult.entryPoints),
        municipality: address.municipality,
        municipalitySubdivision: address.municipalitySubdivision,
        countrySecondarySubdivision: address.countrySecondarySubdivision,
        countryTertiarySubdivision: address.countryTertiarySubdivision,
        extendedPostalCode: address.extendedPostalCode,
        countrySubdivisionName: address.countrySubdivisionName,
        geometryId: searchResult.dataSources?.geometry?.id,
        geographyEntityType: searchResult.entityType
            ? (toConstantCase(searchResult.entityType) as GeographyEntityType)
            : undefined,
        // TTM-only location-info parts:
        localizedCountryName: searchResult.address.country,
        localizedPOIClassificationNames: poi?.classifications?.[0].names.map((name) => name.name),
        openingHours: poi?.openingHours,
        timeZone: poi?.timeZone,
        ngsType: undefined,
        name: undefined,
        link: undefined
    };
    removeEmptyParameters(mappedResult);
    return mappedResult;
};

export const addressToTTMSearchResult = (address: Address, position: { lng: number; lat: number }, address_id?: string): TTMSearchResult =>
    toTTMSearchResult({
        id: address_id || nanoid(),
        type: "Point Address",
        position,
        address
    });

export const getPoint = (location: TTMLocation): GORPoint => {
    if (!location) {
        return null;
    }
    if (location.context === TTMLocationContext.WAYPOINT) {
        return (location as TTMWaypoint).pointLatLon;
    } else if (location.context === TTMLocationContext.MY_PLACES) {
        return (location as TTMUserMapLocation).mapLocation?.pointLatLon;
    }
    return getLocationInfo(location).point;
};

export const toTTMSearchResults = (searchResults: FuzzySearchResult[]): TTMSearchResult[] => {
    return searchResults?.map(toTTMSearchResult);
};

export const locationInfosToMapBounds = (locations: TTMLocationInfo[]): LngLatBounds => {
    const bounds = new LngLatBounds();
    locations.forEach((location) => {
        if (location) {
            bounds.extend([location.point[1], location.point[0]]);
        }
    });
    return bounds;
};

export const isNonEmptyBBox = (bbox: PointBoundingBox) =>
    bbox && bbox.southWest[0] != bbox.northEast[0] && bbox.southWest[1] != bbox.northEast[1];
