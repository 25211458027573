import { Feature, FeatureCollection, LineString, Point, Position } from "geojson";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import { featureCollection, lineString, point } from "@turf/helpers";
import pointToLineDistance from "@turf/point-to-line-distance";
import distance from "@turf/distance";
import { PositionAnchor } from "maplibre-gl";
import {
    GeoInput,
    IntervalSection,
    Itinerary,
    ItineraryPlanningRequest,
    ItinerarySectionGeoInput,
    PlannedItinerary,
    Section,
    TrafficSection,
    UnitsType
} from "@anw/gor-sdk";

import { formatMetersWithUnitsType } from "./units";
import pick from "lodash/pick";
import { getLngLatPath, getWaypoints } from "./itinerary";

const toRouteFeature = (itinerary: Itinerary, index: number): Feature<LineString> => {
    const points = itinerary.segments[0].pathLatLonAlt
        ? itinerary.segments[0].pathLatLonAlt
        : itinerary.segments[0].waypoints.map((waypoint) => waypoint.pointLatLon);
    return lineString(
        points.map((latLon) => [latLon[1], latLon[0]]),
        { index }
    );
};

export const toRouteFeatures = (itineraries: Itinerary[]): FeatureCollection<LineString> => {
    return featureCollection(itineraries ? itineraries.map(toRouteFeature) : []);
};

const toSectionFeature = (routeLineStringPath: Position[], section: Section): Feature<LineString, Section> => {
    return lineString(routeLineStringPath.slice(section.startPointIndex, section.endPointIndex + 1), section);
};

export const toTrimmedPathFeature = (
    routeLineStringPath: Position[],
    startPointIndex: number,
    endPointIndex: number
): Feature<LineString> =>
    endPointIndex > startPointIndex ? lineString(routeLineStringPath.slice(startPointIndex, endPointIndex + 1)) : null;

export const toSectionFeatureCollection = (
    itinerary: Itinerary,
    lineStringPath: Position[],
    sectionType: string,
    sectionFilter?: { (section: Section) }
): FeatureCollection<LineString> => {
    let sections = itinerary.segments[0].groupedSections[sectionType];
    if (sectionFilter && sections) {
        sections = sections.filter(sectionFilter);
    }
    return featureCollection(sections ? sections.map((section) => toSectionFeature(lineStringPath, section)) : []);
};

export const toIntervalSectionPointFeature = (
    routeLineStringPath: Position[],
    intervalSection: IntervalSection,
    foregroundRoute: boolean,
    unitsType: UnitsType
): Feature<Point> => {
    return point(routeLineStringPath[intervalSection.endPointIndex], {
        durationFromStart: Math.floor(intervalSection.durationInSecondsFromStart / 3600) + "h",
        lengthFromStart: formatMetersWithUnitsType(intervalSection.lengthInMetersFromStart, unitsType),
        foregroundRoute
    });
};

const ignoreArriveAndDepartureIfInThePast = (
    arrive: number,
    departure: number
): { arriveTime: number; departTime: number } => {
    let arriveTime, departTime;
    if (departure) {
        departTime = departure > new Date().getTime() ? departure : null;
        // if we have depart we don't care about arrive
    } else if (arrive) {
        arriveTime = arrive > new Date().getTime() ? arrive : null;
    }
    return { arriveTime, departTime };
};

export const buildRequestForItinerary = (
    baseRequest: ItineraryPlanningRequest,
    itinerary: Itinerary
): ItineraryPlanningRequest => ({
    ...baseRequest,
    ...ignoreArriveAndDepartureIfInThePast(itinerary.plannedArrivalTime, itinerary.plannedDepartureTime),
    locale: itinerary.localeLanguage,
    thrillingPreferences: itinerary.thrillingPreferences,
    avoidOptions: itinerary.avoidOptions,
    vehicleParameters: itinerary.vehicleParameters,
    travelMode: itinerary.mainTravelMode === "CAMPER_HEAVY" ? "VAN" : itinerary.mainTravelMode,
    costModel: itinerary.costModel == "EXACT" ? "FASTEST" : itinerary.costModel,
    sortedGeoInputs:
        itinerary.costModel != "EXACT"
            ? itinerary.segments[0].waypoints
            : [
                  {
                      type: "ITINERARY_SECTION",
                      itineraryID: itinerary.id
                  } as ItinerarySectionGeoInput
              ]
});

export const isTrackReconstructionRequestFor = (request: ItineraryPlanningRequest, itineraryID: string): boolean =>
    itineraryID &&
    !request.unsortedGeoInputs &&
    request.sortedGeoInputs.length === 1 &&
    request.sortedGeoInputs[0].type === "ITINERARY_SECTION" &&
    (request.sortedGeoInputs[0] as ItinerarySectionGeoInput).itineraryID === itineraryID;

const geoInputOmitKeys = [
    "locationInfo",
    "durationFromPreviousHardWaypointInSeconds",
    "durationFromStartInSeconds",
    "lengthFromPreviousHardWaypointInMeters",
    "lengthFromStartInMeters",
    "pathPointIndex",
    "embeddedPathMapping",
    "isCurrentLocation"
];

const cleanedForCalculation = (geoInputs: GeoInput[]): GeoInput[] =>
    geoInputs?.map((geoInput) => omit(geoInput, geoInputOmitKeys)) as GeoInput[];

/**
 * Compares two sets of route planning requests to determine if they're different to justify a new route calculation.
 * If basically tries to skip extra parts of the input which wouldn't make a different in a route calculation.
 * @param first The previous planning request.
 * @param second The new planning request.
 */
export const comparePlanningRequests = (first: ItineraryPlanningRequest, second: ItineraryPlanningRequest) => {
    const propsToCompare = [
        "travelMode",
        "costModel",
        "avoidOptions",
        "vehicleParameters",
        "thrillingPreferences",
        "traffic",
        "locale",
        "departTime",
        "arriveTime",
        "timeIntervalMinutes",
        "distanceIntervalKMs",
        "distanceIntervalKMs"
    ];

    const firstPicked = pick(first, propsToCompare);
    const secondPicked = pick(second, propsToCompare);

    if (!isEqual(firstPicked, secondPicked)) {
        return false;
    }

    const sortedCleanGeoInputsFirst = cleanedForCalculation(first.sortedGeoInputs);
    const sortedCleanGeoInputsSecond = cleanedForCalculation(second.sortedGeoInputs);

    if (!isEqual(sortedCleanGeoInputsFirst, sortedCleanGeoInputsSecond)) {
        return false;
    }

    const unsortedCleanGeoInputsFirst = cleanedForCalculation(first.unsortedGeoInputs);
    const unsortedCleanGeoInputsSecond = cleanedForCalculation(second.unsortedGeoInputs);

    return isEqual(unsortedCleanGeoInputsFirst, unsortedCleanGeoInputsSecond);
};

export const hasRoadClosures = (route: Itinerary): boolean =>
    route.segments[0].groupedSections.traffic?.some((section) => section.simpleCategory === "ROAD_CLOSURE");

export const getAvoidedIncidents = (
    plannedRoute: PlannedItinerary,
    historicTrafficRoute?: Itinerary
): TrafficSection[] => {
    if (!historicTrafficRoute || !plannedRoute.avoidedTrafficSections) {
        return [];
    } else {
        const historicTrafficRouteTraffic = historicTrafficRoute.segments[0].groupedSections.traffic;
        return plannedRoute.avoidedTrafficSections.map((sectionIndex) => historicTrafficRouteTraffic[sectionIndex]);
    }
};

export const getTotalDelay = (trafficSections: TrafficSection[]): number =>
    trafficSections.map((section) => section.delayInSeconds).reduce((prev, curr) => prev + curr, 0);

export const isSimilarToHistoricTrafficRoute = (plannedItinerary: PlannedItinerary): boolean =>
    plannedItinerary.historicTrafficItineraryOverlapPCT >= 90 &&
    plannedItinerary.historicTrafficItineraryOverlapPCT < 99;

export const isEqualToHistoricTrafficRoute = (plannedItinerary: PlannedItinerary): boolean =>
    plannedItinerary.historicTrafficItineraryOverlapPCT >= 99;

export const isAnyEqualToHistoricTrafficRoute = (plannedItineraries: PlannedItinerary[]): boolean =>
    plannedItineraries?.some((plannedItinerary) => isEqualToHistoricTrafficRoute(plannedItinerary));

const calculateMostDivergentIndex = (mainPath: number[][], pathsToCheckAgainst: number[][][]): number => {
    let maxDistance = 0;
    let maxDistanceIndex = 0;
    mainPath.forEach((point, index) => {
        if (pathsToCheckAgainst.length === 1) {
            const distance = pointToLineDistance(
                point,
                { type: "LineString", coordinates: pathsToCheckAgainst[0] },
                {
                    units: "kilometers",
                    method: "planar"
                }
            );
            if (distance > 0 && maxDistance < distance) {
                maxDistance = distance;
                maxDistanceIndex = index;
            }
        } else if (pathsToCheckAgainst.length === 2) {
            const distance1 = pointToLineDistance(
                point,
                { type: "LineString", coordinates: pathsToCheckAgainst[0] },
                {
                    units: "kilometers",
                    method: "planar"
                }
            );
            const distance2 = pointToLineDistance(
                point,
                { type: "LineString", coordinates: pathsToCheckAgainst[1] },
                {
                    units: "kilometers",
                    method: "planar"
                }
            );
            if (distance1 > 0 && distance2 > 0 && maxDistance < Math.min(distance1, distance2)) {
                maxDistance = Math.min(distance1, distance2);
                maxDistanceIndex = index;
            }
        }
    });
    if (maxDistanceIndex === 0) {
        // this route always goes the same road as one of the alternative, pick a index in the middle
        return Math.round(mainPath.length / 2);
    }
    return maxDistanceIndex;
};

export const findIndexForPopupThatIsNotClashingWithWaypoints = (itinerary: Itinerary): number => {
    const lngLatPath = getLngLatPath(itinerary);
    const waypoints = getWaypoints(itinerary);
    // we want to search around the middle of the route in both directions until quarter of the route
    // we try to find one that is at least 10% length of the route away from all the waypoints or the furthest one if the rule was not triggered
    // in case of the round trip we start at the quarter ends and go to the middle
    const middleOfRoute = Math.round(lngLatPath.length / 2);
    const quarterOfRoute = Math.round(middleOfRoute / 2);
    let index = middleOfRoute;
    let maxDistanceFromWaypoints = 0;
    let indexFound = false;
    const checkIfPointIsFarEnough = (distances: number[], pointIndex: number) => {
        waypoints.forEach((waypoint) => {
            distances.push(
                distance(lngLatPath[pointIndex], [waypoint.pointLatLon[1], waypoint.pointLatLon[0]], {
                    units: "kilometers"
                })
            );
        });
        if (distances.every((distance) => distance > itinerary.lengthInMeters / 10000)) {
            index = pointIndex;
            indexFound = true;
        } else {
            const sumOfDistances = distances.reduce((a, b) => a + b);
            if (maxDistanceFromWaypoints < sumOfDistances) {
                maxDistanceFromWaypoints = sumOfDistances;
                index = pointIndex;
            }
        }
    };
    for (let i = 1; i < quarterOfRoute; i++) {
        const firstIndex = itinerary.roundTrip ? middleOfRoute - quarterOfRoute + i : middleOfRoute - i;
        const secondIndex = itinerary.roundTrip ? middleOfRoute + quarterOfRoute - i : middleOfRoute + i;
        const distances = [] as number[];
        checkIfPointIsFarEnough(distances, firstIndex);
        if (indexFound) {
            break;
        }
        distances.length = 0;
        checkIfPointIsFarEnough(distances, secondIndex);
        if (indexFound) {
            break;
        }
    }
    return index;
};

export const mostDivergentPointsOfTheRoutes = (itineraries: Itinerary[]): number[] => {
    const maxDistanceIndex = [];
    const lngLatPaths = [] as number[][][];
    const mods = [] as number[];
    itineraries?.forEach((itinerary, index) => {
        const lngLatPath = getLngLatPath(itinerary);
        // 100 points could be enough for the calculation, speed over precision
        // 100 points takes 15ms, 500 points takes 400ms, if this needs to be changed
        let mod = Math.round(lngLatPath.length / 100);
        if (mod === 0) {
            mod++;
        }
        mods[index] = mod;
        lngLatPaths.push(
            lngLatPath.filter((value, index) => {
                return index % mod == 0;
            })
        );
    });
    // always using one index before the max distance because of only using set of points, so when multiplying we don't get out of index error
    if (lngLatPaths.length === 3) {
        // two alternatives
        maxDistanceIndex[0] =
            (calculateMostDivergentIndex(lngLatPaths[0], [lngLatPaths[1], lngLatPaths[2]]) - 1) * mods[0];
        maxDistanceIndex[1] =
            (calculateMostDivergentIndex(lngLatPaths[1], [lngLatPaths[0], lngLatPaths[2]]) - 1) * mods[1];
        maxDistanceIndex[2] =
            (calculateMostDivergentIndex(lngLatPaths[2], [lngLatPaths[0], lngLatPaths[1]]) - 1) * mods[2];
    } else if (lngLatPaths.length === 2) {
        maxDistanceIndex[0] = (calculateMostDivergentIndex(lngLatPaths[0], [lngLatPaths[1]]) - 1) * mods[0];
        maxDistanceIndex[1] = (calculateMostDivergentIndex(lngLatPaths[1], [lngLatPaths[0]]) - 1) * mods[1];
    }
    return maxDistanceIndex;
};

const getAnchorForLatitudeComparison = (
    lngLatPointZero: [number, number],
    lngLatPointOne: [number, number],
    lngLatPointTwo: [number, number]
): PositionAnchor => {
    const lngPointOne = lngLatPointOne[0];
    const lngPointTwo = lngLatPointTwo[0];
    const latPointZero = lngLatPointZero[1];
    const latPointOne = lngLatPointOne[1];
    const latPointTwo = lngLatPointTwo[1];
    if (lngPointOne > lngPointTwo) {
        if (Math.abs(latPointZero - latPointOne) > Math.abs(latPointZero - latPointTwo)) {
            return "left";
        } else {
            return "right";
        }
    } else {
        if (Math.abs(latPointZero - latPointOne) > Math.abs(latPointZero - latPointTwo)) {
            return "right";
        } else {
            return "left";
        }
    }
};

const getAnchorForLongitudeComparison = (
    lngLatPointZero: [number, number],
    lngLatPointOne: [number, number],
    lngLatPointTwo: [number, number]
): PositionAnchor => {
    const lngPointZero = lngLatPointZero[0];
    const lngPointOne = lngLatPointOne[0];
    const lngPointTwo = lngLatPointTwo[0];
    const latPointOne = lngLatPointOne[1];
    const latPointTwo = lngLatPointTwo[1];
    if (latPointOne > latPointTwo) {
        if (Math.abs(lngPointZero - lngPointOne) > Math.abs(lngPointZero - lngPointTwo)) {
            return "bottom";
        } else {
            return "top";
        }
    } else {
        if (Math.abs(lngPointZero - lngPointOne) > Math.abs(lngPointZero - lngPointTwo)) {
            return "top";
        } else {
            return "bottom";
        }
    }
};

export const calculateAnchorForRoutePopup = (
    northSouth: boolean,
    index: number,
    lngLatPaths: [number, number][][],
    maxDistanceIndexes: number[],
    bbox: [number, number, number, number]
): PositionAnchor => {
    if (lngLatPaths.length === 1) {
        return northSouth ? "left" : "bottom";
    }
    if (!isNil(bbox[0])) {
        // we need to see if the coordinates of the bubble are to close to the bounding box (10% of bbox size), if they are we need to push the bubbles inside the bounding box
        const lngPopupPoint = lngLatPaths[index][maxDistanceIndexes[index]][0];
        if (bbox[0] + Math.abs(bbox[2] - bbox[0]) / 10 > lngPopupPoint) {
            return "left";
        }
        if (bbox[2] - Math.abs(bbox[2] - bbox[0]) / 10 < lngPopupPoint) {
            return "right";
        }
    }
    const lngLatPointZero = lngLatPaths[0][maxDistanceIndexes[0]];
    const lngLatPointOne = lngLatPaths[1][maxDistanceIndexes[1]];
    const lngPointZero = lngLatPointZero[0];
    const lngPointOne = lngLatPointOne[0];
    const latPointZero = lngLatPointZero[1];
    const latPointOne = lngLatPointOne[1];
    if (lngLatPaths.length === 2) {
        if (northSouth) {
            // need to decide left or right, the left most route will have anchor to the right, the right most route will have anchor to the left
            if ((index === 0 && lngPointZero < lngPointOne) || (index === 1 && lngPointOne < lngPointZero)) {
                return "right";
            } else {
                return "left";
            }
        } else {
            // need to decide top or bottom, the bottom most route will have anchor to the top
            if ((index === 0 && latPointZero < latPointOne) || (index === 1 && latPointOne < latPointZero)) {
                return "top";
            } else {
                return "bottom";
            }
        }
    } else if (lngLatPaths.length === 3) {
        const lngLatPointTwo = lngLatPaths[2][maxDistanceIndexes[2]];
        const lngPointTwo = lngLatPointTwo[0];
        const latPointTwo = lngLatPointTwo[1];
        if (northSouth) {
            // need to decide left or right, the left most route will have anchor to the right, the right most route will have anchor to the left
            // and the middle one depends which other point is closer to go other way
            if (
                (index === 0 && lngPointZero < Math.min(lngPointOne, lngPointTwo)) ||
                (index === 1 && lngPointOne < Math.min(lngPointZero, lngPointTwo)) ||
                (index === 2 && lngPointTwo < Math.min(lngPointZero, lngPointOne))
            ) {
                return "right";
            } else if (
                (index === 0 && lngPointZero > Math.max(lngPointOne, lngPointTwo)) ||
                (index === 1 && lngPointOne > Math.max(lngPointZero, lngPointTwo)) ||
                (index === 2 && lngPointTwo > Math.max(lngPointZero, lngPointOne))
            ) {
                return "left";
            } else {
                // middle one depending on the latitude proximity to other points we go left or right
                switch (index) {
                    case 0:
                        return getAnchorForLatitudeComparison(lngLatPointZero, lngLatPointOne, lngLatPointTwo);
                    case 1:
                        return getAnchorForLatitudeComparison(lngLatPointOne, lngLatPointZero, lngLatPointTwo);
                    case 2:
                        return getAnchorForLatitudeComparison(lngLatPointTwo, lngLatPointZero, lngLatPointOne);
                }
            }
        } else {
            // need to decide top or bottom, the bottom most route will have anchor to the top
            if (
                (index === 0 && latPointZero < Math.min(latPointOne, latPointTwo)) ||
                (index === 1 && latPointOne < Math.min(latPointZero, latPointTwo)) ||
                (index === 2 && latPointTwo < Math.min(latPointZero, latPointOne))
            ) {
                return "top";
            } else if (
                (index === 0 && latPointZero > Math.max(latPointOne, latPointTwo)) ||
                (index === 1 && latPointOne > Math.max(latPointZero, latPointTwo)) ||
                (index === 2 && latPointTwo > Math.max(latPointZero, latPointOne))
            ) {
                return "bottom";
            } else {
                // middle one depending on the longitude proximity to other points we go left or right
                switch (index) {
                    case 0:
                        return getAnchorForLongitudeComparison(lngLatPointZero, lngLatPointOne, lngLatPointTwo);
                    case 1:
                        return getAnchorForLongitudeComparison(lngLatPointOne, lngLatPointZero, lngLatPointTwo);
                    case 2:
                        return getAnchorForLongitudeComparison(lngLatPointTwo, lngLatPointZero, lngLatPointOne);
                }
            }
        }
    }
};

/**
 * (Experimental feature)
 * Calculates from a given list of itineraries which one is the curviest and least curvy (approximately).
 * @param plannedItineraries The itineraries to scan for curve scores.
 */
export const calcCurveHighlights = (
    plannedItineraries: PlannedItinerary[]
): { curviestIndex?: number; leastCurvyIndex?: number } => {
    if (!plannedItineraries) {
        return {};
    }
    let curviestIndex = undefined;
    let leastCurvyIndex = undefined;

    const thrilling = plannedItineraries[0].itinerary.costModel === "THRILLING";
    const curveScores = plannedItineraries.map((plannedItinerary) =>
        plannedItinerary.itinerary.segments[0].groupedSections.curve?.reduce(
            (prev, curr) => prev + (thrilling && curr.relatedToManeuvering ? 0 : Math.abs(curr.bearingChangeDegrees)),
            0
        )
    );

    // we check if any route has enough curves for its duration:
    const anyRouteCurvyEnough = curveScores.find(
        (score, index) => score / plannedItineraries[index].itinerary.durationInSeconds > 0.4
    );
    if (anyRouteCurvyEnough) {
        curveScores.forEach((score, index) => {
            if (curviestIndex == undefined || curveScores[curviestIndex] < score) {
                curviestIndex = index;
            }
            if (leastCurvyIndex == undefined || curveScores[leastCurvyIndex] > score) {
                leastCurvyIndex = index;
            }
        });
        return { curviestIndex, leastCurvyIndex };
    } else {
        return {};
    }
};

export const getThrillLimit = (unitType: UnitsType): string => (unitType === "METRIC" ? "1000 km" : "600 mi");
