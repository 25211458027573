import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";

/**
 * Run a given callback when the value accessed by the `key` property on the `T`
 * object is different across `prev` to `curr` objects.
 * Uses `lodash.isEqual` as comparision method.
 *
 * @param key Key for value access in objects
 * @param prev
 * @param curr
 * @param cb Callback triggered with `curr[key]` as parameter
 * @param skipCheck Should skip equal check and trigger callback
 */
export const onPropDiffer = <T extends AnyObject>(
    key: keyof T,
    prev: T,
    curr: T,
    cb: (currentProp: T[typeof key], prevProp?: T[typeof key]) => void,
    skipCheck = false
) => {
    const currentProp = curr[key];
    const previousProp = prev[key];

    if (skipCheck || !isEqual(currentProp, previousProp)) {
        cb(currentProp, previousProp);
    }
};

export const onValueDiffer = <T>(
    prev: T,
    curr: T,
    callback: (currentValue: T, previousValue?: T) => void,
    forceCallback = false,
    customIsEqual?: (currentValue: T, previousValue?: T) => boolean
) => {
    if (forceCallback || (customIsEqual ? !customIsEqual(prev, curr) : !isEqual(prev, curr))) {
        callback(curr, prev);
    }
};

// We delete null and undefined properties from the object:
// using recursion to clean up nested objects as well
export const removeEmptyParameters = (obj) => {
    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            removeEmptyParameters(v);
        } else if (isNil(v)) {
            delete obj[k];
        }
    });
};
