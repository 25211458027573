import { Instruction } from "@anw/gor-sdk";
import { lineString } from "@turf/helpers";
import length from "@turf/length";

export const interpolateTimeInSecondsBetweenPathIndexes = (
    instructions: Instruction[],
    startPathIndex: number,
    endPathIndex: number
): number => {
    let foundStartPathIndex = false;
    let timeToStartPathIndex = 0;
    for (let i = 0; i < instructions.length; i++) {
        const instruction = instructions[i];
        if (!foundStartPathIndex) {
            if (startPathIndex > instruction.pointIndex) {
                continue;
            }
            foundStartPathIndex = true;
            if (startPathIndex === instruction.pointIndex) {
                timeToStartPathIndex = instruction.travelTimeInSeconds;
            } else {
                const timeBetweenTwoInstructions =
                    instruction.travelTimeInSeconds - instructions[i - 1]?.travelTimeInSeconds;
                const secondsPerPoint =
                    timeBetweenTwoInstructions / (instruction.pointIndex - instructions[i - 1]?.pointIndex);
                timeToStartPathIndex = parseInt(
                    (
                        instructions[i - 1]?.travelTimeInSeconds +
                        secondsPerPoint * (startPathIndex - instructions[i - 1]?.pointIndex)
                    ).toString()
                );
            }
        }
        if (foundStartPathIndex) {
            if (endPathIndex > instruction.pointIndex) {
                continue;
            }
            if (endPathIndex === instruction.pointIndex) {
                return instruction.travelTimeInSeconds - timeToStartPathIndex;
            } else {
                const timeBetweenTwoInstructions =
                    instruction.travelTimeInSeconds - instructions[i - 1]?.travelTimeInSeconds;
                const secondsPerPoint =
                    timeBetweenTwoInstructions / (instruction.pointIndex - instructions[i - 1]?.pointIndex);
                return parseInt(
                    (
                        instructions[i - 1]?.travelTimeInSeconds +
                        secondsPerPoint * (endPathIndex - instructions[i - 1]?.pointIndex) -
                        timeToStartPathIndex
                    ).toString()
                );
            }
        }
    }
    // something strange has happened
    return 0;
};

export const interpolateDistanceInMetersBetweenPathIndexes = (
    instructions: Instruction[],
    startPathIndex: number,
    endPathIndex: number
): number => {
    let foundStartPathIndex = false;
    let lengthToStartPathIndex = 0;
    for (let i = 0; i < instructions.length; i++) {
        const instruction = instructions[i];
        if (!foundStartPathIndex) {
            if (startPathIndex > instruction.pointIndex) {
                continue;
            }
            foundStartPathIndex = true;
            if (startPathIndex === instruction.pointIndex) {
                lengthToStartPathIndex = instruction.routeOffsetInMeters;
            } else {
                const distanceBetweenTwoInstructions =
                    instruction.routeOffsetInMeters - instructions[i - 1]?.routeOffsetInMeters;
                const metersPerPoint =
                    distanceBetweenTwoInstructions / (instruction.pointIndex - instructions[i - 1]?.pointIndex);
                lengthToStartPathIndex = parseInt(
                    (
                        instructions[i - 1]?.routeOffsetInMeters +
                        metersPerPoint * (startPathIndex - instructions[i - 1]?.pointIndex)
                    ).toString()
                );
            }
        }
        if (foundStartPathIndex) {
            if (endPathIndex > instruction.pointIndex) {
                continue;
            }
            if (endPathIndex === instruction.pointIndex) {
                return instruction.routeOffsetInMeters - lengthToStartPathIndex;
            } else {
                const distanceBetweenTwoInstructions =
                    instruction.routeOffsetInMeters - instructions[i - 1]?.routeOffsetInMeters;
                const metersPerPoint =
                    distanceBetweenTwoInstructions / (instruction.pointIndex - instructions[i - 1]?.pointIndex);
                return parseInt(
                    (
                        instructions[i - 1]?.routeOffsetInMeters +
                        metersPerPoint * (endPathIndex - instructions[i - 1]?.pointIndex) -
                        lengthToStartPathIndex
                    ).toString()
                );
            }
        }
    }
    // something strange has happened
    return 0;
};

export const calculateDistanceInMetersBetweenPathIndexes = (
    pathLatLonAlt: number[][],
    startIndex,
    endIndex
): number => {
    let slicedArray;
    if (endIndex === pathLatLonAlt.length - 1) {
        slicedArray = pathLatLonAlt.slice(startIndex);
    } else {
        slicedArray = pathLatLonAlt.slice(startIndex, endIndex + 1);
    }
    const geoJson = lineString(slicedArray.map((latLong) => [latLong[1], latLong[0]]));
    const lengthInKilometers = length(geoJson);
    return parseInt((lengthInKilometers * 1000).toString());
};
