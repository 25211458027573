export type ChargingStationAvailability =
    | "Unspecified"
    | "NoRestriction"
    | "GenericRestriction"
    | "ResidentsOnly"
    | "EmployeesOnly"
    | "AuthorizedPersonnelOnly"
    | "MembersOnly";

export type ChargingPointStatus = "Available" | "Reserved" | "Occupied" | "OutOfService" | "Unknown";

export type Connector = {
    type: string;
    ratedPowerKW: number;
    voltageV: number;
    currentA: number;
    currentType: string;
};

export type ChargingPoint = {
    evseId: string;
    status: ChargingPointStatus;
    connectors: Connector[];
};

export type ChargingStation = {
    chargingStationId: string;
    accessibility: ChargingStationAvailability;
    chargingPoints: ChargingPoint[];
};

export type EvPOI = {
    chargingParkId: string;
    chargingStations: ChargingStation[];
};

export type EvInfoByConnector = {
    type: string;
    powerKW: number;
    availableConnectorsNumber: number;
    unknownConnectorsNumber: number;
    totalConnectorsNumber: number;
};

export const getEvInfoByConnectors = (chargingStations: ChargingStation[]): EvInfoByConnector[] => {
    const evInfoByConnectors: EvInfoByConnector[] = [];
    chargingStations.forEach((chargingStation) => {
        chargingStation.chargingPoints.forEach((chargingPoint) => {
            chargingPoint.connectors.forEach((connector) => {
                const existingType = evInfoByConnectors.find(
                    (info) => connector.type == info.type && connector.ratedPowerKW == info.powerKW
                );
                if (existingType) {
                    existingType.availableConnectorsNumber =
                        existingType.availableConnectorsNumber + (chargingPoint.status == "Available" ? 1 : 0);
                    existingType.totalConnectorsNumber = existingType.totalConnectorsNumber + 1;
                    existingType.unknownConnectorsNumber =
                        existingType.unknownConnectorsNumber + (chargingPoint.status == "Unknown" ? 1 : 0);
                } else {
                    evInfoByConnectors.push({
                        type: connector.type,
                        powerKW: connector.ratedPowerKW,
                        availableConnectorsNumber: chargingPoint.status == "Available" ? 1 : 0,
                        unknownConnectorsNumber: chargingPoint.status == "Unknown" ? 1 : 0,
                        totalConnectorsNumber: 1
                    });
                }
            });
        });
    });
    return evInfoByConnectors;
};
