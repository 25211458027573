export const chargeInKWhToPercentage = (currentChargeInkWh: number, maxChargeInkWh: number): number => {
    if (currentChargeInkWh && maxChargeInkWh) {
        return Math.round((currentChargeInkWh * 100) / maxChargeInkWh);
    }
    return undefined;
};

export const chargeInPercentageTokWh = (currentChargeInPercentage: number, maxChargeInkWh: number): number => {
    if (currentChargeInPercentage && maxChargeInkWh) {
        return (currentChargeInPercentage * maxChargeInkWh) / 100;
    }
    return undefined;
};
