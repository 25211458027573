export const languageToMapLocale = {
    en: "en-GB",
    es: "es-ES",
    nl: "nl-NL",
    de: "de-DE",
    fr: "fr-FR",
    it: "it-IT",
    pt: "pt-PT",
    sv: "sv-SE",
    cs: "cs-CZ",
    da: "da-DK",
    el: "el-GR",
    fi: "fi-FI",
    hr: "hr-HR",
    no: "no-NO",
    ru: "ru-RU",
    sl: "sl-SL",
    tr: "tr-TR",
    zh: "zh-CN"
};
