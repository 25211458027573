import { AvoidOption, CostModel, LNHPreference } from "@anw/gor-sdk";
import { AxiosResponse } from "axios";
import { Polygon } from "geojson";

export const toReachableRangeRouteType = (costModel: CostModel): "fastest" | "shortest" | "eco" | "thrilling" => {
    switch (costModel) {
        case "FASTEST":
            return "fastest";
        case "SHORTEST":
            return "shortest";
        case "THRILLING":
            return "thrilling";
        case "ECO":
            return "eco";
        default:
            return undefined;
    }
};

export const toReachableRangeLNH = (lnhPreference: LNHPreference): "low" | "normal" | "high" => {
    switch (lnhPreference) {
        case "LOW":
            return "low";
        case "NORMAL":
            return "normal";
        case "HIGH":
            return "high";
        default:
            return undefined;
    }
};

export const toReachableRangeAvoidable = (
    avoidOption: AvoidOption
): "tollRoads" | "motorways" | "ferries" | "unpavedRoads" | "carpools" | "alreadyUsedRoads" | "borderCrossings" => {
    switch (avoidOption) {
        case "TOLL_ROADS":
            return "tollRoads";
        case "MOTORWAYS":
            return "motorways";
        case "FERRIES":
            return "ferries";
        case "UNPAVED_ROADS":
            return "unpavedRoads";
        case "CARPOOLS":
            return "carpools";
        case "ALREADY_USED_ROADS":
            return "alreadyUsedRoads";
        case "BORDER_CROSSINGS":
            return "borderCrossings";
        default:
            return undefined;
    }
};

export const toGeoJson = (reachableRangeResponse: AxiosResponse): GeoJSON.Feature<Polygon> => {
    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: [
                reachableRangeResponse.data.reachableRange.boundary.map((latlon) => [latlon.longitude, latlon.latitude])
            ]
        }
    } as GeoJSON.Feature<Polygon>;
};
