// NOTE: this mapping should we replaced by a proper SDK one once supported

//TODO category id should be used here
export const poiClassificationToIconID = {
    7389: 310, //access gateway
    7305: 322, // adventure sports venue
    7332005: 318, // market
    AGRICULTURE: 307,
    AGRICULTURE_BUSINESS: 307,
    AIRPORT: 141,
    AMUSEMENT_PARK: 173,
    ASHRAM: 160,
    ATM: 242,
    AUTOMOTIVE_DEALER: 314,
    BANK: 277,
    BEACH: 243,
    BUILDING_POINT: 132,
    BUSINESS_PARK: 302,
    CAFE_PUB: 320,
    CAMPING_GROUND: 258,
    CAR_WASH: 267,
    CASH_DISPENSER: 242,
    CASINO: 209,
    CHURCH: 155,
    CINEMA: 211,
    CITY_CENTER: 12,
    CLUB_ASSOCIATION: 331,
    COLLEGE_UNIVERSITY: 156,
    COMMERCIAL_BUILDING: 298,
    COMMUNITY_CENTER: 281,
    COMPANY: 213,
    CONCERT_HALL: 250,
    COURTHOUSE: 215,
    CULTURAL_CENTER: 216,
    DENTIST: 248,
    DEPARTMENT_STORE: 304,
    DOCTOR: 247,
    ELECTRIC_VEHICLE_STATION: 273,
    EMBASSY: 240,
    EMERGENCY_MEDICAL_SERVICE: 315,
    EMERGENCY_ROOM: 315,
    ENTERTAINMENT: 235,
    EXCHANGE: 296,
    EXHIBITION_CONVENTION_CENTER: 217,
    FERRY_TERMINAL: 145,
    FIRE_STATION_BRIGADE: 164,
    FRONTIER_CROSSING: 219,
    FUEL_FACILITIES: 204,
    GAS_STATION: 204,
    GEOGRAPHIC_FEATURE: 127,
    GOLF_COURSE: 171,
    GOVERNMENT_OFFICE: 166,
    GURUDWARA: 160,
    HEALTH_CARE_SERVICE: 316,
    HELIPAD: 323,
    HELIPAD_HELICOPTER_LANDING: 323,
    HILL: 158,
    HOLIDAY_RENTAL: 130,
    HOSPITAL: 157,
    HOSPITAL_POLYCLINIC: 157,
    HOTEL_MOTEL: 222,
    ICE_SKATING_RINK: 244,
    IMPORTANT_TOURIST_ATTRACTION: 154,
    INDUSTRIAL_BUILDING: 172,
    LEISURE_CENTER: 321,
    LIBRARY: 252,
    MANUFACTURING_FACILITY: 299,
    MARINA: 262,
    MARKET: 318,
    MEDIA_FACILITY: 301,
    MILITARY_INSTALLATION: 170,
    MOSQUE: 160,
    MOTORING_ORGANIZATION_OFFICE: 276,
    MOUNTAIN_PASS: 224,
    MOUNTAIN_PEAK: 158,
    MOVIE_THEATER: 211,
    MUSEUM: 176,
    NATIVE_RESERVATION: 325,
    NIGHTLIFE: 250,
    NON_GOVERNMENTAL_ORGANIZATION: 334,
    OPEN_PARKING_AREA: 159,
    OPERA: 26,
    PAGODA: 160,
    PARK_RECREATION_AREA: 169,
    PARKING_GARAGE: 203,
    PETROL_STATION: 204,
    PHARMACY: 254,
    PLACE_OF_WORSHIP: 155,
    POLICE_STATION: 161,
    PORT_WAREHOUSE_FACILITY: 305,
    POST_CODE: 63,
    POST_OFFICE: 165,
    PRIMARY_RESOURCE_UTILITY: 308,
    PRISON_CORRECTIONAL_FACILITY: 167,
    PUBLIC_AMENITY: 297,
    PUBLIC_TRANSPORT_STOP: 142,
    RAILROAD_STATION: 147,
    RAILWAY_STATION: 147,
    RENT_A_CAR_FACILITY: 229,
    RENT_A_CAR_PARKING: 230,
    REPAIR_FACILITY: 253,
    RESEARCH_FACILITY: 300,
    RESIDENTIAL_ACCOMMODATION: 303,
    REST_AREA: 206,
    RESTAURANT: 231,
    RESTAURANT_AREA: 31,
    SCENIC_PANORAMIC_VIEW: 255,
    SCHOOL: 156,
    SHOP: 162,
    SHOPPING_CENTER: 163,
    SPORTS_CENTER: 238,
    STADIUM: 177,
    SWIMMING_POOL: 256,
    SYNAGOG: 160,
    TEMPLE: 160,
    TENNIS_COURT: 45,
    THEATER: 235,
    TOLL_GATE: 284,
    TOURIST_INFORMATION_OFFICE: 236,
    TRAFFIC_LIGHT: 129,
    TRAFFIC_SERVICE_CENTER: 326,
    TRAFFIC_SIGN: 128,
    TRAIL_SYSTEM: 324,
    TRANSPORT_AUTHORITY_VEHICLE_REGISTRATION: 313,
    TRUCK_STOP: 312,
    VACATION_RENTAL: 330,
    VETERINARIAN: 249,
    WATER_SPORT: 246,
    WEIGH_STATION: 311,
    WELFARE_ORGANIZATION: 317,
    WINERY: 257,
    ZOOS_ARBORETA_BOTANICAL_GARDEN: 168,
    9352: 213
};

export const poiClassificationFromIconID = (iconID) => {
    return Object.keys(poiClassificationToIconID).find((key) => poiClassificationToIconID[key] === iconID);
};
