import { Feature, LineString, Point } from "geojson";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { Coord } from "@turf/helpers";
import { buildLocationInfoTitle, getPoint } from "./location";
import { TTMLocation, TTMWaypoint } from "./locationTypes";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import { GeoInput, Itinerary, ItineraryPlanningRequest, ItineraryPlanningResponse, Waypoint } from "@anw/gor-sdk";
import { isTrackReconstructionRequestFor } from "./route";

export const START_INDEX = "start";
export const MIDDLE_INDEX = "middle";
export const FINISH_INDEX = "finish";

export const isChargingStop = (geoInput: GeoInput) => geoInput && (geoInput as Waypoint)?.chargingInformation != null;

export const waypointToFeature = (
    waypoint: TTMWaypoint,
    index: number,
    array: TTMWaypoint[],
    waypointToPlannerInputIndexes: number[]
): Feature<Point> => {
    if (!waypoint) {
        return undefined;
    }
    const FinishOrMiddleIndex = index >= array.length - 1 ? FINISH_INDEX : MIDDLE_INDEX;
    return {
        type: "Feature",
        properties: {
            id: waypoint.locationInfo?.externalID,
            title: buildLocationInfoTitle(waypoint.locationInfo),
            type: waypoint.type,
            isChargingStop: isChargingStop(waypoint),
            index,
            plannerInputIndex: waypointToPlannerInputIndexes[index],
            indexType: index === 0 ? START_INDEX : FinishOrMiddleIndex
        },
        geometry: {
            type: "Point",
            coordinates: [waypoint.pointLatLon[1], waypoint.pointLatLon[0]]
        }
    };
};

export type NewWaypointIndex = { value: number; addMode: "INSERT" | "REPLACE" | "UNSORTED" };

/**
 * Finds the ideal new index to replace or insert a new waypoint.
 * Finds, from empty waypoint slots, the candidate to be filled for a new waypoint.
 * Here we assume that typically we can have up to 2 empty slots when we only have 2 waypoints.
 * When we have more than 2 waypoints, we assume we should have no more than one empty slot at a time.
 * If we can't fill an empty slot, then we try to find an insertion slot based on the given point and route, if any.
 * If the above attempts fail, -1 is returned, suggesting the client doesn't know where to add the waypoint effectively.
 * @param waypoints The waypoints to consider (which can contain nulls inside as empty slots).
 * @param geoJSONPoint The optional point where the waypoint will be.
 * @param routeFeature The optional existing route to be used as a reference.
 */
export const calcNewWaypointIndex = (
    waypoints: TTMWaypoint[],
    geoJSONPoint?: Coord,
    routeFeature?: Feature<LineString>
): NewWaypointIndex => {
    const firstPlaceholderIndex = findIndex(waypoints, (waypoint) => !waypoint);
    if (firstPlaceholderIndex != -1) {
        return { value: firstPlaceholderIndex, addMode: "REPLACE" };
    }
    // else
    if (geoJSONPoint && routeFeature) {
        const newPathPointIndex = nearestPointOnLine(routeFeature.geometry, geoJSONPoint).properties.index;
        const newWaypointIndex =
            // (if the closest path point is roughly near the end, we assume a new destination)
            newPathPointIndex >= routeFeature.geometry.coordinates.length - 5
                ? waypoints.length
                : waypoints.findIndex((waypoint) => waypoint.pathPointIndex > newPathPointIndex);
        return {
            value: newWaypointIndex,
            addMode: "INSERT"
        };
    }
    return { value: -1, addMode: "UNSORTED" };
};

export const findIndexOfMatchingWaypoint = (waypoints: TTMWaypoint[], locationToMatch: TTMLocation): number => {
    const locationPoint = getPoint(locationToMatch);
    return waypoints.findIndex((waypoint) => isEqual(waypoint?.pointLatLon, locationPoint));
};

const areWaypointsSimilar = (wA: Waypoint, wB: Waypoint) => isEqual(wA?.pointLatLon, wB?.pointLatLon);

const isWaypointOrPlaceholder = (geoInput: GeoInput) =>
    !geoInput || (geoInput.type == "HARD" && !isChargingStop(geoInput)) || geoInput.type == "SOFT";
const isWaypointOrPlaceholderOrChargingStop = (geoInput: GeoInput) =>
    !geoInput || geoInput.type == "HARD" || geoInput.type == "SOFT";

export const findFirstPlaceholderIndex = (
    waypoints: TTMWaypoint[],
    options?: { ignoreIndexes?: number[] }
): number | undefined => {
    const result = waypoints.findIndex((waypoint, index) => !waypoint && !options?.ignoreIndexes?.includes(index));
    return result === -1 ? undefined : result;
};

const areGeoInputsSimilar = (geoInputA: GeoInput, geoInputB: GeoInput) => {
    if (isWaypointOrPlaceholder(geoInputA) && isWaypointOrPlaceholder(geoInputB)) {
        return areWaypointsSimilar(geoInputA as Waypoint, geoInputB as Waypoint);
    }
    return isEqual(geoInputA, geoInputB);
};

const isGeoInputSimilarToWaypoint = (geoInput: GeoInput, waypoint: TTMWaypoint) =>
    geoInput &&
    waypoint &&
    geoInput.type === waypoint.type &&
    isEqual((geoInput as Waypoint).pointLatLon, waypoint.pointLatLon);

export const isRoundTrip = (filledGeoInputs: GeoInput[]) =>
    filledGeoInputs.length > 1 && areGeoInputsSimilar(filledGeoInputs[0], filledGeoInputs[filledGeoInputs.length - 1]);

/**
 * Finds the current changed or affected waypoints when comparing them to the previous ones.
 * @param filledPrev Filled-in previous waypoints. Expected to be from the most recent previous state.
 * @param filledCurr Filled-in current waypoints.
 * @see Unit test data to understand expectations in more detail.
 */
export const currentAffectedWaypoints = (filledPrev: TTMWaypoint[], filledCurr: TTMWaypoint[]): TTMWaypoint[] => {
    if (isRoundTrip(filledCurr) && !isRoundTrip(filledPrev)) {
        // edge case: if we detect changing to round trip, we consider all stops as affected:
        return filledCurr;
    }
    const affectedWaypoints = [] as TTMWaypoint[];
    // added (or negative if removed) current waypoints compared to previous ones from the current iteration point:
    let addedWaypointsSoFar = 0;
    // we iterate the current waypoints list:
    filledCurr.forEach((currWaypoint, i) => {
        // and for each current we check against the prev list on a similar index, compensating for added and removed ones:
        const prevToCheck = filledPrev[i - addedWaypointsSoFar];
        if (!areWaypointsSimilar(currWaypoint, prevToCheck)) {
            if (!filledPrev.some((prevWaypoint) => areWaypointsSimilar(prevWaypoint, currWaypoint))) {
                // Added current waypoint:
                addedWaypointsSoFar++;
                affectedWaypoints.push(currWaypoint);
            } else if (!filledCurr.some((currWaypoint) => areWaypointsSimilar(prevToCheck, currWaypoint))) {
                // Removed current waypoint:
                addedWaypointsSoFar--;
            } else {
                // Moved current waypoint:
                affectedWaypoints.push(currWaypoint);
            }
        }
    });
    return affectedWaypoints;
};

/**
 * Merges a request waypoint with the expected extra calculated fields from a response one.
 * Fields which aren't expected to change after a calculation aren't altered (coordinates, location info...)
 * @param requestWaypoint The request waypoint corresponding the response one (should be for the actual request for that response).
 * @param responseWaypoint The response waypoint, as coming in the response itinerary.
 */
export const mergedFromResponse = (requestWaypoint: TTMWaypoint, responseWaypoint: Waypoint): TTMWaypoint => {
    const updatedRequestWaypoint = { ...requestWaypoint, type: responseWaypoint.type };
    if (responseWaypoint.durationFromPreviousHardWaypointInSeconds) {
        updatedRequestWaypoint.durationFromPreviousHardWaypointInSeconds =
            responseWaypoint.durationFromPreviousHardWaypointInSeconds;
    } else {
        delete updatedRequestWaypoint.durationFromPreviousHardWaypointInSeconds;
    }
    if (responseWaypoint.durationFromStartInSeconds) {
        updatedRequestWaypoint.durationFromStartInSeconds = responseWaypoint.durationFromStartInSeconds;
    } else {
        delete updatedRequestWaypoint.durationFromStartInSeconds;
    }
    if (responseWaypoint.lengthFromPreviousHardWaypointInMeters) {
        updatedRequestWaypoint.lengthFromPreviousHardWaypointInMeters =
            responseWaypoint.lengthFromPreviousHardWaypointInMeters;
    } else {
        delete updatedRequestWaypoint.lengthFromPreviousHardWaypointInMeters;
    }
    if (responseWaypoint.lengthFromStartInMeters) {
        updatedRequestWaypoint.lengthFromStartInMeters = responseWaypoint.lengthFromStartInMeters;
    } else {
        delete updatedRequestWaypoint.lengthFromStartInMeters;
    }
    if (responseWaypoint.embeddedPathMapping) {
        updatedRequestWaypoint.embeddedPathMapping = responseWaypoint.embeddedPathMapping;
    } else {
        delete updatedRequestWaypoint.embeddedPathMapping;
    }
    if (responseWaypoint.pathPointIndex) {
        updatedRequestWaypoint.pathPointIndex = responseWaypoint.pathPointIndex;
    } else {
        delete updatedRequestWaypoint.pathPointIndex;
    }
    return updatedRequestWaypoint;
};

export const hasNonWaypointGeoInputs = (geoInputs: GeoInput[]): boolean =>
    !!geoInputs?.find((geoInput) => !isWaypointOrPlaceholderOrChargingStop(geoInput));

export const waypointsOnlyWithPlaceholders = (geoInputs: GeoInput[]): TTMWaypoint[] => {
    const result = geoInputs.filter((geoInput) => isWaypointOrPlaceholder(geoInput)) as TTMWaypoint[];
    return result.length ? result : [null, null];
};
export const waypointsOnlyWithPlaceholdersAndChargingStops = (geoInputs: GeoInput[]): TTMWaypoint[] => {
    const result = geoInputs.filter((geoInput) => isWaypointOrPlaceholderOrChargingStop(geoInput)) as TTMWaypoint[];
    return result.length ? result : [null, null];
};

const updateFromResponseWaypoints = (
    requestSortedGeoInputs: GeoInput[],
    responseWaypoints: Waypoint[]
): TTMWaypoint[] => {
    if (!requestSortedGeoInputs && !responseWaypoints) {
        return [null, null];
    } else if (
        !requestSortedGeoInputs ||
        // For now we don't do advanced mapping from non-waypoint geo-inputs to response waypoints. We default just to response waypoints then.
        // This can be the case of a track reconstruction where the input is 1 itinerary section: there it's good to just use response waypoints from now on.
        requestSortedGeoInputs.find((input) => input && !isWaypointOrPlaceholder(input))
    ) {
        return responseWaypoints;
    } else if (!responseWaypoints) {
        return waypointsOnlyWithPlaceholders(requestSortedGeoInputs);
    } else if (requestSortedGeoInputs.length !== responseWaypoints.length) {
        if (responseWaypoints.find((input) => input && isChargingStop(input))) {
            return responseWaypoints;
        } else {
            console.warn(
                `Waypoint list sizes do not match: ${requestSortedGeoInputs.length} vs ${responseWaypoints.length}`
            );
            return requestSortedGeoInputs as TTMWaypoint[];
        }
    } else {
        return requestSortedGeoInputs.map((requestWaypoint, index) =>
            mergedFromResponse(requestWaypoint as TTMWaypoint, responseWaypoints[index])
        );
    }
};

/**
 * Builds a new list of sorted waypoints based on the given requestSorted ones, with requestSorted merged into them, and extra response waypoints data merged.
 * @param requestSorted The source request sorted waypoints. The result will contain references to them in the original relative order.
 * @param requestUnsorted The source request sorted waypoints. The result will contain references to them in the right order.
 * @param responseWaypoints The fresh response waypoints, used as a guide to rebuild the request ones.
 */
export const updateWaypointsFromResponse = (
    requestSorted: GeoInput[],
    requestUnsorted: GeoInput[],
    responseWaypoints: Waypoint[]
): {
    resolvedSorted: TTMWaypoint[];
    unsortedResolvedIndexes?: number[];
} => {
    if (!requestUnsorted || !requestUnsorted.length || !responseWaypoints || !responseWaypoints.length) {
        return { resolvedSorted: updateFromResponseWaypoints(requestSorted, responseWaypoints) };
    }

    // else
    const updatedSorted = [...requestSorted];
    const unsortedToSortedIndexes = [];
    requestUnsorted.forEach((unsortedWaypoint) => {
        const responseIndex =
            responseWaypoints.findIndex((responseWaypoint) =>
                isGeoInputSimilarToWaypoint(unsortedWaypoint, responseWaypoint)
            ) || requestSorted.length;
        updatedSorted.splice(responseIndex, 0, unsortedWaypoint);
        unsortedToSortedIndexes.push(responseIndex);
    });

    return {
        resolvedSorted: updateFromResponseWaypoints(updatedSorted, responseWaypoints),
        unsortedResolvedIndexes: unsortedToSortedIndexes
    };
};

export const updatedFromResponse = (
    request: ItineraryPlanningRequest,
    response: ItineraryPlanningResponse,
    selectedItinerary: Itinerary
): { updatedRequest: ItineraryPlanningRequest; unsortedResolvedIndexes?: number[] } => {
    const responseWaypoints = response.plannedItineraries[0]?.itinerary?.segments[0].waypoints;
    if (isTrackReconstructionRequestFor(request, selectedItinerary?.id)) {
        // if this request was to reconstruct the given itinerary as a track,
        // then we'll replace the request start and end waypoints with the itinerary ones, to preserve geolocation info.
        const selectedItineraryWaypoints = selectedItinerary.segments[0].waypoints;
        return {
            updatedRequest: {
                ...request,
                sortedGeoInputs: [
                    selectedItineraryWaypoints[0],
                    ...(responseWaypoints && responseWaypoints.slice(1, responseWaypoints.length - 1)),
                    selectedItineraryWaypoints[selectedItineraryWaypoints.length - 1]
                ]
            }
        };
    } else {
        const updatedSortedWaypoints = updateWaypointsFromResponse(
            request.sortedGeoInputs,
            request.unsortedGeoInputs,
            responseWaypoints
        );
        const updatedRequest = {
            ...request,
            sortedGeoInputs: updatedSortedWaypoints.resolvedSorted
        };
        delete updatedRequest.unsortedGeoInputs;
        return { updatedRequest, unsortedResolvedIndexes: updatedSortedWaypoints.unsortedResolvedIndexes };
    }
};

export const isPlannerInputWaypoint = (waypoint: Waypoint): boolean =>
    waypoint?.type !== "SOFT" && !isChargingStop(waypoint);

/**
 * Builds the bi-directional mappings between full sorted waypoints list indexes and planner input waypoint ones.
 * @param sortedWaypoints The full list of sorted waypoints, as expected in the current route planning criteria.
 */
export const waypointIndexMappings = (
    sortedWaypoints: Waypoint[]
): { plannerInputToWaypointIndexes: number[]; waypointToPlannerInputIndexes: number[] } => {
    const plannerInputToWaypointIndexes = [];
    const waypointToPlannerInputIndexes = [];
    let plannedInputWaypointIndex = 0;
    sortedWaypoints.forEach((waypoint, index) => {
        if (isPlannerInputWaypoint(waypoint)) {
            plannerInputToWaypointIndexes.push(index);
            waypointToPlannerInputIndexes.push(plannedInputWaypointIndex);
            plannedInputWaypointIndex++;
        } else {
            waypointToPlannerInputIndexes.push(null);
        }
    });
    return { plannerInputToWaypointIndexes, waypointToPlannerInputIndexes };
};

export const withoutSoftWaypoints = (geoInputs: GeoInput[]): GeoInput[] =>
    geoInputs.filter((geoInput) => geoInput?.type !== "SOFT");

export const withoutChargingStops = (geoInputs: GeoInput[]): GeoInput[] =>
    geoInputs.filter((geoInput) => !isChargingStop(geoInput));
