import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";

const sum = (x: number, ...rest: number[]) => rest.reduce((acc, n) => acc + n, x);

const minus = (x: number, ...rest: number[]) => rest.reduce((acc, n) => acc - n, x);

/**
 * Return an interval of integers between a `low` and a `high` bound.
 * If `low` is bigger than `high` then interval is decreasing.
 * @param low
 * @param high
 * @returns An interval between a low and a high bound
 */
export const interval = (low: number, high: number) => {
    const length = Math.abs(high - low) + 1;
    const op = low > high ? minus : sum;

    return Array.from(new Array(length)).map((_, i) => {
        return op(low, i);
    });
};

export const toFixedLngLat = (val: number, numDecimals = 5) => Number(val.toFixed(numDecimals));
export const toFixedExtra = (val: number) => Number(val.toFixed(2));

export const sameCoordinates = (pointA: [number, number], pointB: [number, number], numDecimals = 5): boolean => {
    if (isNil(pointA) || isNil(pointB)) {
        return false;
    }
    return isEqual(
        [toFixedLngLat(pointA[0], numDecimals), toFixedLngLat(pointA[1], numDecimals)],
        [toFixedLngLat(pointB[0], numDecimals), toFixedLngLat(pointB[1], numDecimals)]
    );
};
