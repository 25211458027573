import Qty from "js-quantities";
import { UnitsType } from "@anw/gor-sdk";
import { TimeRange } from "@tomtom-international/web-sdk-services";
import isNil from "lodash/isNil";
import moment from "moment-timezone";
import { TFunction } from "i18next";

// constants for formatting
const MILE = "mi";
const METER = "m";
const KILOMETER = "km";
const FEET = "ft";
const YARD = "yd";
const TEN_M_PRECISION = "10 m";
const HUNDRED_M_PRECISION = "100 m";
const TEN_FT_PRECISION = "10 ft";
const TEN_YD_PRECISION = "10 yd";
const HUNDRED_FT_PRECISION = "100 ft";
const FEET_AND_INCHES = /^(\d+)'(\d+)"$/;
const FEET_AND_INCHES_FORMATTER = /^(0('|0?")?|[1-9]\d{0,2}(['"])?|[1-9]\d?'\d{1,2}"?)$/;
const INCH = "inch";

const formatMilesLessThanThree = (qty: Qty): string => {
    // logic to properly put quarter, half and three quarter chars
    const numberOfMilesAbs = parseInt(Math.abs(qty.scalar).toString());
    const numberOfMiles = parseInt(qty.scalar.toString());
    const floatPart = Math.abs(qty.scalar) - numberOfMilesAbs;
    if (floatPart < 0.125) {
        return `${numberOfMiles} mi`;
    } else if (floatPart < 0.375) {
        return `${numberOfMilesAbs > 0 ? numberOfMiles : qty.scalar < 0 ? "-" : ""}¼ mi`;
    } else if (floatPart < 0.625) {
        return `${numberOfMilesAbs > 0 ? numberOfMiles : qty.scalar < 0 ? "-" : ""}½ mi`;
    } else if (floatPart < 0.875) {
        return `${numberOfMilesAbs > 0 ? numberOfMiles : qty.scalar < 0 ? "-" : ""}¾ mi`;
    } else {
        return `${numberOfMiles + (qty.scalar < 0 ? -1 : 1)} mi`;
    }
};

const formatMilesLessThanTen = (qty: Qty): string => {
    // logic to properly put half char
    const numberOfMilesAbs = parseInt(Math.abs(qty.scalar).toString());
    const numberOfMiles = parseInt(qty.scalar.toString());
    const floatPart = Math.abs(qty.scalar) - numberOfMilesAbs;
    if (floatPart < 0.25) {
        return `${numberOfMiles} mi`;
    } else if (floatPart < 0.75) {
        return `${numberOfMiles}½ mi`;
    } else {
        return `${numberOfMiles + (qty.scalar < 0 ? -1 : 1)} mi`;
    }
};

const formatMiles = (qty: Qty): string => {
    if (Math.abs(qty.scalar) < 3) {
        return formatMilesLessThanThree(qty);
    } else if (Math.abs(qty.scalar) < 10) {
        return formatMilesLessThanTen(qty);
    } else {
        return qty.toPrec(MILE).format();
    }
};

/**
 * Formatting is based on the number of meters passed and unit type. Less meters more precision.
 * Logic is copied from MDW application.
 * @param meters
 * @param unitsType
 */
export const formatMetersWithUnitsType = (meters: number, unitsType: UnitsType): string => {
    if (!meters) {
        return "";
    }
    let qty = new Qty(meters, METER);
    switch (unitsType) {
        case "METRIC":
            if (Math.abs(meters) < 10) {
                return qty.toPrec(METER).format();
            } else if (Math.abs(meters) < 500) {
                return qty.toPrec(TEN_M_PRECISION).format();
            } else if (Math.abs(meters) < 1000) {
                qty = qty.toPrec(HUNDRED_M_PRECISION);
                return Math.abs(qty.scalar) == 1000 ? qty.format(KILOMETER) : qty.format();
            } else if (Math.abs(meters) < 10000) {
                return qty.toPrec(HUNDRED_M_PRECISION).format(KILOMETER);
            } else {
                return qty.toPrec(KILOMETER).format(KILOMETER);
            }
        case "IMPERIAL_US":
            qty = qty.to(MILE);
            if (Math.abs(qty.scalar) < 0.125) {
                // display in feet
                qty = qty.to(FEET);
                if (Math.abs(qty.scalar) < 30) {
                    return qty.toPrec(FEET).format();
                } else if (Math.abs(qty.scalar) < 500) {
                    return qty.toPrec(TEN_FT_PRECISION).format();
                } else {
                    return qty.toPrec(HUNDRED_FT_PRECISION).format();
                }
            } else {
                return formatMiles(qty);
            }
        case "IMPERIAL_UK":
            qty = qty.to(MILE);
            if (Math.abs(qty.scalar) < 0.125) {
                // display in yards
                qty = qty.to(YARD);
                if (Math.abs(qty.scalar) < 10) {
                    return qty.toPrec(YARD).format();
                } else {
                    return qty.toPrec(TEN_YD_PRECISION).format();
                }
            } else {
                return formatMiles(qty);
            }
    }
};

/**
 * Formats feet and inches to make it look something like 1'12".
 * @param text value to be formatted.
 * @return formatted value.
 */
export const formatFeetAndInches = (text: string): string => {
    if (text && FEET_AND_INCHES_FORMATTER.exec(text)) {
        const tokens: string[] = text.split(/['"]/).filter((el) => el != "");
        let feet = 0;
        let inches = 0;
        if (tokens.length > 0) {
            if (tokens[0].includes(".")) {
                const numberParts = tokens[0].split(".");
                feet = parseInt(numberParts[0], 10);
                inches = parseInt((Number("0." + numberParts[1]) * 12).toString(), 10);
            } else {
                feet = parseInt(tokens[0], 10);
            }
        }
        if (tokens.length > 1) {
            inches += parseInt(tokens[1], 10);
        } else if (text.includes('"')) {
            inches = feet;
            feet = 0;
        }
        feet = parseInt(feet >= 0 ? (feet + inches / 12).toString() : (feet - inches / 12).toString(), 10);
        inches = inches % 12;
        return (feet == 0 ? "" : feet + "'") + (inches ? inches + '"' : "");
    }
    return text;
};

/**
 * If the string contains feet and inch values, for example 12'5", it will return the quantity holding the value in inches.
 * @param text
 */
export const extractFeetAndInchesMeasureInInches = (text: string): Qty => {
    const match = FEET_AND_INCHES.exec(text);
    if (match) {
        const feet = parseInt(match[1], 10);
        const fullQuantityInInches = parseInt(match[2], 10) + feet * 12;
        return new Qty(fullQuantityInInches, INCH);
    } else {
        const qty = Qty.parse(text);
        if (qty) {
            return qty.to(INCH).toPrec(INCH);
        }
        return qty;
    }
};

export const formatDuration = (seconds: number): string => {
    // get the absolute value for seconds to calculate the right formatting
    const absSeconds = Math.abs(seconds);
    const hours = absSeconds / 3600;
    let flooredHours = Math.floor(hours);
    let minutes = Math.round((hours % 1) * 60);
    if (minutes === 60) {
        minutes = 0;
        flooredHours++;
    }
    const minOrEmptyText = minutes ? `${minutes.toString()} min` : "";
    return flooredHours ? `${flooredHours} hr ${minutes.toString().padStart(2, "0")} min` : minOrEmptyText;
};

/**
 * Returns the amount of calendar days the second date is ahead from the first one.
 * Note the "calendar" remark. If a date is 2 minutes after the other but in the next calendar day, the result will be 1.
 * If the second date is before the first one, the result will be negative.
 * @param first The first date.
 * @param second The second date.
 */
export const numCalendarDaysAhead = (first: Date, second: Date): number =>
    // We calculate the timezone-less difference of both dates excluding anything more detailed than days:
    Math.floor(
        (Date.UTC(second.getFullYear(), second.getMonth(), second.getDate()) -
            Date.UTC(first.getFullYear(), first.getMonth(), first.getDate())) /
            (1000 * 60 * 60 * 24)
    );

/**
 * The context where a date is to be used.
 */
export type DateContext = "DEPART_ARRIVE" | "ROUTE_SUMMARY" | "TRAFFIC";

const timePrefix = (context: DateContext, t: TFunction) =>
    context === "DEPART_ARRIVE" ? t("Units:time_prefix") + " " : "";

const toLocaleDateString = (date: Date, locale: string) =>
    date.toLocaleString(locale, {
        month: "numeric",
        day: "numeric",
        year: date.getFullYear() !== new Date().getFullYear() ? "numeric" : undefined
    });

const roundTimeToMin = (date: Date) => new Date(Math.round(date.getTime() / 60000) * 60000);
export const isDistExceeds1000KM = (dist: number) => dist / 1000 > 1000;

/**
 * Formats the given time-date into a locale-friendly string, which also takes into account the current date.
 * If the given time is today, the resulting formatted date will contain only the time part.
 * @param d A ms timestamp, formatted Date string, or Date object.
 * @param context The context in which this date is to be used.
 * @param t translation function
 * @param locale The locale with which to format the date. Defaults to en-GB.
 */
export const formatDate = (d: number | string | Date, context: DateContext, t: TFunction, locale = "en-GB"): string => {
    const date = roundTimeToMin(d instanceof Date ? d : new Date(d));
    const time = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit"
    });
    const numDaysAhead = numCalendarDaysAhead(new Date(), date);
    if (numDaysAhead === 0) {
        return `${timePrefix(context, t)}${time}`;
    } else if (numDaysAhead === 1) {
        return t(`Units:${context === "ROUTE_SUMMARY" ? "tomorrow_prefix_route" : "tomorrow_prefix"}`, { time });
    } else if (context === "TRAFFIC") {
        const timePrefix = numDaysAhead >= 0 && numDaysAhead <= 7 ? t("Units:time_prefix_traffic", { time }) + " " : "";
        return `${timePrefix}${toLocaleDateString(date, locale)}`;
    } else {
        return t("Units:time_prefix_full", {
            time: `${timePrefix(context, t)}${time}`,
            date: `${toLocaleDateString(date, locale)}`
        });
    }
};

export const toRadians = (n: number) => (n * Math.PI) / 180;

export const toDegrees = (n: number) => (n * 180) / Math.PI;

/**
 * This method checks if the the present moment is in between timeRange sent.
 * @param timeRange for which we check if present moment is in between.
 * @param timezone for the time range.
 */
export const nowIsInTimeRange = (timeRange: TimeRange, timezone: string): boolean => {
    if (
        isNil(timeRange) ||
        isNil(timeRange.startTime) ||
        isNil(timeRange.startTime.date) ||
        isNil(timeRange.startTime.hour) ||
        isNil(timeRange.startTime.minute) ||
        isNil(timeRange.endTime) ||
        isNil(timeRange.endTime.date) ||
        isNil(timeRange.endTime.hour) ||
        isNil(timeRange.endTime.minute) ||
        isNil(timezone)
    ) {
        // missing data
        return false;
    }
    const startTime = moment.tz(
        `${timeRange.startTime.date} ${String(timeRange.startTime.hour).padStart(2, "0")}:${String(
            timeRange.startTime.minute
        ).padStart(2, "0")}`,
        timezone
    );
    const endTime = moment.tz(
        `${timeRange.endTime.date} ${String(timeRange.endTime.hour).padStart(2, "0")}:${String(
            timeRange.endTime.minute
        ).padStart(2, "0")}`,
        timezone
    );
    return moment().isBetween(startTime, endTime);
};

export const unitsTypeFromCountryCode = (countryCode: string): UnitsType => {
    switch (countryCode) {
        case "US":
            return "IMPERIAL_US";
        case "GB":
            return "IMPERIAL_UK";
        default:
            // metric is by default
            return "METRIC";
    }
};
