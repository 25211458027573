const withReplaceOrInsert =
    (replace?: boolean) =>
    <T>(list: T[], item: T, index?: number) => {
        const copy = list.slice();
        if (typeof index !== "undefined") {
            copy.splice(index, replace ? 1 : 0, item);
        } else {
            copy.push(item);
        }
        return copy;
    };

export const withInsertionAt = withReplaceOrInsert(false);

export const withReplaceAt = withReplaceOrInsert(true);

/**
 * @param list Input list. Might be read-only. Will not be modified.
 * @param index The index of the element to be removed in the output list.
 * @return A new array based on the input array minus the element at 'index'. If you know your input array is mutable, you can use splice instead.
 */
export const withRemovalAt = <T>(list: T[], index: number): T[] => {
    return list.slice(0, index).concat(list.slice(index + 1, list.length));
};

export const ensureList = <T>(l: T | T[]) => (Array.isArray(l) ? l : [l]);
