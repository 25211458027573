import { Tag, UserProfile } from "@anw/gor-sdk";

/**
 * Returns whether the given user profile is entitled to Rider Routes.
 * @param userProfile The user profile to check.
 */
export const isUserEntitledToRiderRoutes = (userProfile: UserProfile): boolean =>
    !!userProfile?.readableItineraryCollectionTags.find(
        (tag: Tag) => tag.tagType === "TOMTOM_PREMIUM_ITINERARY_COLLECTION" && tag.name.toLowerCase().includes("rider")
    );
