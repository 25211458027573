import { CostModel, Itinerary, LocationInfo, MediaContentItem, Waypoint } from "@anw/gor-sdk";
import isNil from "lodash/isNil";
import truncate from "lodash/truncate";
import { TFunction } from "i18next";
import { toSearchSimplifiedText } from "./myPlaces";
import { TTMItinerary } from "./itineraryTypes";

export const getLatLongPath = (itinerary: Itinerary): number[][] => itinerary?.segments?.[0].pathLatLonAlt || [];

export const getLngLatPath = (itinerary: Itinerary): [number, number][] =>
    getLatLongPath(itinerary).map((point) => [point[1], point[0]]);

export const getWaypoints = (itinerary: Itinerary): Waypoint[] => itinerary?.segments?.[0].waypoints || [];

export const formatCostModel = (costModel: CostModel, t: TFunction): string => {
    return t("common:cost_model." + costModel.toLowerCase());
};

export const hardWaypoints = (itinerary: Itinerary): Waypoint[] => {
    return getWaypoints(itinerary).filter((waypoint) => waypoint.type === "HARD");
};

export const numberOfHardWaypoints = (itinerary: Itinerary): number => {
    return hardWaypoints(itinerary).length;
};

export const waypointsLocationInfo = (itinerary: Itinerary): LocationInfo[] => {
    return hardWaypoints(itinerary).map((waypoint) => waypoint.locationInfo);
};

export const formatTitle = (itinerary: Itinerary, short?: boolean): string => {
    return short ? truncate(itinerary.name, { length: 30 }) : itinerary.name;
};

export const formatSubtitle = (itinerary: Itinerary, t: TFunction): string => {
    const numberOfStops = numberOfHardWaypoints(itinerary) - 2;
    return (
        formatCostModel(itinerary.costModel, t) +
        (numberOfStops > 0 ? " | " + numberOfStops + t("common:stops") : "") +
        (itinerary.avoidOptions
            ? " | " +
              t("common:avoids") +
              itinerary.avoidOptions
                  .map((avoidOption) => t("common:avoidable_options." + avoidOption.toLowerCase()))
                  .join(", ")
            : "")
    );
};

export const getImages = (itinerary: Itinerary, sizeStr = "thumbnail") =>
    itinerary.contentItems
        ?.filter((item) => item.type === "MEDIA")
        .map((item) => (item as MediaContentItem).reference?.replace("%7Bsize%7D", sizeStr));

export const getDestination = (itinerary: Itinerary): Waypoint => {
    if (isNil(itinerary) || !itinerary.segments) {
        return null;
    }
    const waypoints = itinerary.segments[0].waypoints;
    return waypoints[waypoints.length - 1];
};

export const withMyRouteSearchTexts = (itineraries: Itinerary[]): TTMItinerary[] =>
    itineraries.map((itinerary) => ({
        ...itinerary,
        searchText: toSearchSimplifiedText(itinerary.name)
    }));
