import RemoteLogger from "../classes/RemoteLogger";

export const logBoundaryError = (
    error: Error,
    info: {
        componentStack: string;
    },
    boundaryName: string
) => {
    RemoteLogger.log({
        severity: "error",
        message: "Uncaught error",
        category: `RecoveryErrorBoundary${boundaryName}`,
        data: {
            // js error message
            errorMessage: error?.message ?? error,
            // js error info
            errorStackSample: error?.stack ? error.stack.split("\n").slice(0, 20).join("\n") : "",
            // react error info
            info
        }
    });
};
