import { ItineraryPlanningResponse } from "@anw/gor-sdk";
import isNil from "lodash/isNil";

import { isRoundTrip } from "./waypoint";
import { TealiumLogger } from "../classes/TealiumLogger";

// list of often used analytics constants for method and/or origin
export const MAIN_LIST = "main_list";
export const LOCATION_PANEL = "location_panel";
export const MAP_CONTEXTUAL_PANEL = "map_contextual_panel";
export const IN_LINE_PLANNER = "in_line_planner";
export const MYITEMS_PLACES = "myitems_places";

const calculateDifferenceInHours = (departTime: number) => {
    return Math.round(((departTime - Date.now()) * 100) / (1000 * 60 * 60)) / 100;
};

/**
 * Convenience method to log a link event for route found
 */
export const trackRouteFoundEvent = (
    response: ItineraryPlanningResponse,
    effectiveRequest,
    error,
    method: "drag_route" | "default" = "default"
) => {
    let changeTime, changeTimeDifferenceInHours;
    if (effectiveRequest.departTime) {
        // depart time set
        changeTime = "depart";
        changeTimeDifferenceInHours = calculateDifferenceInHours(effectiveRequest.departTime);
    } else if (effectiveRequest.arriveTime) {
        //arrive time set
        changeTime = "arrive";
        if (response?.plannedItineraries) {
            changeTimeDifferenceInHours = calculateDifferenceInHours(
                response.plannedItineraries[0].itinerary.plannedDepartureTime
            );
        }
    } else {
        changeTime = "now";
    }
    if (response?.plannedItineraries) {
        // track that we got a new route, using data from the first to send data, ignoring alternatives for now
        const itinerary = response.plannedItineraries[0].itinerary;
        TealiumLogger.link({
            event_name: "route_planned",
            method,
            vehicle_type: effectiveRequest.travelMode,
            number_stops:
                (effectiveRequest.sortedGeoInputs?.length || 0) + (effectiveRequest.unsortedGeoInputs?.length || 0),
            route_length_in_meters: itinerary.lengthInMeters,
            route_duration_in_seconds: itinerary.durationInSeconds,
            round_trip: itinerary.roundTrip,
            avoid_options: itinerary.avoidOptions?.join(", "),
            cost_model: itinerary.costModel,
            change_time: changeTime,
            change_time_difference_in_hours: changeTimeDifferenceInHours
        });
    } else {
        TealiumLogger.link({
            event_name: "route_planned",
            method,
            vehicle_type: effectiveRequest.travelMode,
            number_stops:
                (effectiveRequest.sortedGeoInputs?.length || 0) + (effectiveRequest.unsortedGeoInputs?.length || 0),
            round_trip: effectiveRequest.sortedGeoInputs ? isRoundTrip(effectiveRequest.sortedGeoInputs) : false,
            avoid_options: effectiveRequest.avoidOptions?.filter((a) => !isNil(a)).join(", "),
            cost_model: effectiveRequest.costModel,
            change_time: changeTime,
            change_time_difference_in_hours: changeTimeDifferenceInHours,
            failed: error?.response?.status
        });
    }
};
