import { ItineraryPlanningRequest, service, UnitsType } from "@anw/gor-sdk";
import pointToLineDistance from "@turf/point-to-line-distance";
import distance from "@turf/distance";
import compact from "lodash/compact";
import { withInsertionAt, withReplaceAt } from "./lists";
import { formatDuration, formatMetersWithUnitsType } from "./units";
import { TTMLocation, TTMLocationContext, TTMWaypoint } from "./locationTypes";
import { PlannedRouteInformation } from "../state/tree/map-page/planner/reducers";
import { calcNewWaypointIndex } from "./waypoint";
import { getLocationInfo, getPoint } from "./location";
import isNil from "lodash/isNil";

type DistanceTimeResult = {
    time: string;
    distance: string;
    isNegativeTime: boolean;
    isNegativeDistance: boolean;
    isLongDistance?: boolean;
};

export const defaultDistanceTime: DistanceTimeResult = {
    time: null,
    distance: null,
    isNegativeTime: false,
    isNegativeDistance: false,
    isLongDistance: false
};

type calcDistanceTimeArgs = {
    isRoutePlanned: boolean;
    currentPlannerParams: ItineraryPlanningRequest;
    plannedRouteInfo: PlannedRouteInformation;
    activeRouteIndex: number;
    location: TTMLocation;
    userLngLat: [number, number];
    unitsType: UnitsType;
};

export const calcDistanceTime = async ({
    isRoutePlanned,
    currentPlannerParams,
    plannedRouteInfo,
    activeRouteIndex,
    location,
    userLngLat,
    unitsType
}: calcDistanceTimeArgs): Promise<DistanceTimeResult> => {
    const point = getPoint(location);
    const locationAsNewWaypoint: TTMWaypoint = {
        type: "HARD",
        pointLatLon: getPoint(location),
        locationInfo: getLocationInfo(location)
    };

    try {
        if (isRoutePlanned) {
            if (location.context == TTMLocationContext.WAYPOINT) {
                return defaultDistanceTime;
            } else {
                const plannedItinerary = plannedRouteInfo.response?.plannedItineraries?.[activeRouteIndex];
                const plannedFeatures = plannedRouteInfo.responseFeatures?.features[activeRouteIndex];
                const straightDistance = plannedFeatures && pointToLineDistance([point[1], point[0]], plannedFeatures);
                if (straightDistance > 1000) {
                    return {
                        ...defaultDistanceTime,
                        distance: formatMetersWithUnitsType(straightDistance * 1000, unitsType),
                        isNegativeDistance: straightDistance < 0,
                        isLongDistance: true
                    };
                }
                let sortedGeoInputs, unsortedGeoInputs;

                const calculatedIndex = calcNewWaypointIndex(
                    currentPlannerParams.sortedGeoInputs as TTMWaypoint[],
                    [point[1], point[0]],
                    plannedRouteInfo.responseFeatures?.features[activeRouteIndex]
                );

                if (calculatedIndex.addMode === "INSERT") {
                    if (isNil(currentPlannerParams.sortedGeoInputs[calculatedIndex.value])) {
                        sortedGeoInputs = withReplaceAt(
                            currentPlannerParams.sortedGeoInputs,
                            locationAsNewWaypoint,
                            calculatedIndex.value
                        );
                    } else {
                        sortedGeoInputs = compact(
                            withInsertionAt(
                                currentPlannerParams.sortedGeoInputs,
                                locationAsNewWaypoint,
                                calculatedIndex.value
                            )
                        );
                    }
                } else if (calculatedIndex.addMode === "REPLACE") {
                    sortedGeoInputs = withReplaceAt(
                        currentPlannerParams.sortedGeoInputs,
                        locationAsNewWaypoint,
                        calculatedIndex.value
                    );
                } else {
                    sortedGeoInputs = currentPlannerParams.sortedGeoInputs;
                    unsortedGeoInputs = [locationAsNewWaypoint];
                }
                const result =
                    location &&
                    (await service().api.itineraryPlanning.plan({
                        payload: {
                            ...currentPlannerParams,
                            sortedGeoInputs,
                            unsortedGeoInputs,
                            view: "SUMMARY",
                            numAlternatives: 0
                        },
                        cancellable: false
                    }));
                const resultItinerary = result?.plannedItineraries?.[0]?.itinerary;
                const detourTime = resultItinerary.durationInSeconds - plannedItinerary.itinerary.durationInSeconds;
                const detourDistance = resultItinerary.lengthInMeters - plannedItinerary.itinerary.lengthInMeters;
                return {
                    time: formatDuration(detourTime),
                    distance: formatMetersWithUnitsType(detourDistance, unitsType),
                    isNegativeTime: detourTime < 0,
                    isNegativeDistance: detourDistance < 0
                };
            }
        } else if (userLngLat) {
            const straightDistance = distance([point[1], point[0]], userLngLat);
            if (straightDistance > 1000) {
                return {
                    ...defaultDistanceTime,
                    distance: formatMetersWithUnitsType(straightDistance * 1000, unitsType),
                    isNegativeDistance: straightDistance < 0,
                    isLongDistance: true
                };
            }
            const result =
                location &&
                (await service().api.itineraryPlanning.plan({
                    payload: {
                        ...currentPlannerParams,
                        sortedGeoInputs: [
                            { type: "HARD", pointLatLon: [userLngLat[1], userLngLat[0]] } as TTMWaypoint,
                            locationAsNewWaypoint
                        ],
                        view: "SUMMARY",
                        numAlternatives: 0
                    },
                    cancellable: false
                }));
            const resultItinerary = result?.plannedItineraries?.[0]?.itinerary;
            return {
                time: formatDuration(resultItinerary.durationInSeconds),
                distance: formatMetersWithUnitsType(resultItinerary.lengthInMeters, unitsType),
                isNegativeTime: resultItinerary.durationInSeconds < 0,
                isNegativeDistance: resultItinerary.lengthInMeters < 0
            };
        }
    } catch (e) {
        return defaultDistanceTime;
    }

    return defaultDistanceTime;
};
