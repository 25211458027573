import { UserMapLocation, UserMapLocationType } from "@anw/gor-sdk";
import { TTMLocation, TTMLocationContext, TTMUserMapLocation } from "./locationTypes";
import { getLocationInfo } from "./location";
import omit from "lodash/omit";

// this should not be translated, values for NC
export const defaultNameForType = (type: UserMapLocationType) => {
    switch (type) {
        case "HOME":
            return "Home";
        case "WORK":
            return "Work";
        default:
            return null;
    }
};

/**
 * Calculates a score between 0 and 1 indicating how much the two given strings match.
 * This function works best for search when the string arguments are passed by "toSearchSimplifiedText" first.
 * (We do not automatically use "toSearchSimplifiedText" within this function for performance).
 * @param stringA First string. Ideally the input string.
 * @param stringB Second string. Ideally the string to compare against.
 */
export const calcMatchScore = (stringA: string, stringB: string): number => {
    if (stringB.includes(stringA) || stringA.includes(stringB)) {
        if (stringB.length >= stringA.length) {
            return stringA.length / stringB.length;
        } else {
            return stringB.length / stringA.length;
        }
    }
    return 0;
};

/**
 * Returns a simplified version of the given text for search purposes.
 * By comparing simplified texts we can tolerate non alphanumeric characters and diacritics.
 * @param text An input text to simplify.
 */
export const toSearchSimplifiedText = (text: string): string =>
    text ? text.normalize("NFKD").replace(/[^\w]/g, "").toLowerCase() : "";

export const withMyPlaceSearchTexts = (savedLocations: UserMapLocation[]): TTMUserMapLocation[] =>
    savedLocations &&
    savedLocations.map((location) => ({
        ...location,
        searchText: toSearchSimplifiedText(location.mapLocation.locationInfo?.customName)
    }));

export const asSavedLocation = (location: TTMLocation): TTMUserMapLocation =>
    location.context == TTMLocationContext.MY_PLACES ? (location as TTMUserMapLocation) : null;

export const getMatchFromSavedLocations = (
    locationToMatch: TTMLocation,
    savedLocations: TTMUserMapLocation[]
): TTMUserMapLocation => {
    if (!locationToMatch || !savedLocations?.length) {
        return null;
    }
    let foundMatch = null;
    // If the given location is already a saved one, we try to match it by saved location ID:
    if (locationToMatch.context == TTMLocationContext.MY_PLACES) {
        const savedLocation = locationToMatch as TTMUserMapLocation;
        foundMatch = savedLocations.find((item) => item.id == savedLocation.id);
    }
    if (foundMatch) {
        return foundMatch;
    } else {
        // Otherwise, (non saved location, or stale saved location) we try to match it by location info ID (this is not too robust in case of duplicates):
        const locationInfo = getLocationInfo(locationToMatch);
        if (!locationInfo || !locationInfo.externalID) {
            return null;
        }
        return savedLocations.find((item) => item.mapLocation?.locationInfo?.externalID == locationInfo.externalID);
    }
};

/**
 * Returns an updated version of the given selected location based on a recent change in my-places.
 * If the selected location wasn't related to saved places, returns it unchanged.
 * @param selectedLocation The current selected location.
 * @param prevSavedLocations The previous saved locations, right before the recent change.
 * @param currSavedLocations The current saved locations, right after the recent change.
 */
export const getSelectedLocationAfterSavedPlacesChanged = (
    selectedLocation: TTMLocation,
    prevSavedLocations: TTMUserMapLocation[],
    currSavedLocations: TTMUserMapLocation[]
): { location: TTMLocation; changed: boolean } => {
    if (selectedLocation) {
        if (prevSavedLocations?.length <= currSavedLocations?.length) {
            // Assuming added/updated saved location or fresh login.
            const matchFromSavedLocations = getMatchFromSavedLocations(selectedLocation, currSavedLocations);
            // If we find the match from saved locations, we update the selected location with it.
            // Otherwise, we return the unchanged one (could be the case when logging in and a non-matching location was selected):
            return matchFromSavedLocations
                ? {
                      location: {
                          ...matchFromSavedLocations,
                          context: TTMLocationContext.MY_PLACES
                      },
                      changed: true
                  }
                : { location: selectedLocation, changed: false };
        } else if (
            prevSavedLocations?.length > currSavedLocations?.length &&
            selectedLocation.context == TTMLocationContext.MY_PLACES
        ) {
            // Removed saved location. Replacing selected saved location with generic one:
            return {
                location: {
                    ...omit(getLocationInfo(selectedLocation), "customName"),
                    context: TTMLocationContext.SEARCH_RESULT
                },
                changed: true
            };
        }
    }
    // else
    return { location: selectedLocation, changed: false };
};
