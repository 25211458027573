import { poiClassificationToIconID } from "./poiClassificationsMapping";

export type POICategoryGroup = {
    label: string;
    iconIDs: number[];
};

export const POICategoryGroups: POICategoryGroup[] = [
    {
        label: "food_drink",
        iconIDs: [
            poiClassificationToIconID.RESTAURANT,
            poiClassificationToIconID.CAFE_PUB,
            poiClassificationToIconID.WINERY
        ]
    },
    {
        label: "shopping",
        iconIDs: [
            poiClassificationToIconID.SHOP,
            poiClassificationToIconID.SHOPPING_CENTER,
            poiClassificationToIconID.MARKET,
            poiClassificationToIconID.DEPARTMENT_STORE
        ]
    },
    {
        label: "transportation",
        iconIDs: [
            poiClassificationToIconID.AIRPORT,
            poiClassificationToIconID.FERRY_TERMINAL,
            poiClassificationToIconID.HELIPAD,
            poiClassificationToIconID.HELIPAD_HELICOPTER_LANDING,
            poiClassificationToIconID.PUBLIC_TRANSPORT_STOP,
            poiClassificationToIconID.RAILWAY_STATION,
            poiClassificationToIconID.RAILROAD_STATION
        ]
    },
    {
        label: "health",
        iconIDs: [
            poiClassificationToIconID.DOCTOR,
            poiClassificationToIconID.EMERGENCY_MEDICAL_SERVICE,
            poiClassificationToIconID.EMERGENCY_ROOM,
            poiClassificationToIconID.HEALTH_CARE_SERVICE,
            poiClassificationToIconID.HOSPITAL,
            poiClassificationToIconID.HOSPITAL_POLYCLINIC,
            poiClassificationToIconID.PHARMACY,
            poiClassificationToIconID.DENTIST,
            poiClassificationToIconID.WELFARE_ORGANIZATION
        ]
    },
    {
        label: "parking",
        iconIDs: [poiClassificationToIconID.PARKING_GARAGE, poiClassificationToIconID.OPEN_PARKING_AREA]
    },
    {
        label: "holiday_tourism",
        iconIDs: [
            poiClassificationToIconID.AMUSEMENT_PARK,
            poiClassificationToIconID.BEACH,
            poiClassificationToIconID.HOLIDAY_RENTAL,
            poiClassificationToIconID.GEOGRAPHIC_FEATURE,
            poiClassificationToIconID.IMPORTANT_TOURIST_ATTRACTION,
            poiClassificationToIconID.LEISURE_CENTER,
            poiClassificationToIconID.MOUNTAIN_PASS,
            poiClassificationToIconID.MOUNTAIN_PEAK,
            poiClassificationToIconID.MUSEUM,
            poiClassificationToIconID.SCENIC_PANORAMIC_VIEW,
            poiClassificationToIconID.TOURIST_INFORMATION_OFFICE
        ]
    },
    { label: "ev_charging_stations", iconIDs: [poiClassificationToIconID.ELECTRIC_VEHICLE_STATION] },
    {
        label: "gas_stations",
        iconIDs: [poiClassificationToIconID.GAS_STATION, poiClassificationToIconID.PETROL_STATION]
    },
    {
        label: "accommodation",
        iconIDs: [
            poiClassificationToIconID.CAMPING_GROUND,
            poiClassificationToIconID.HOTEL_MOTEL,
            poiClassificationToIconID.HOLIDAY_RENTAL
        ]
    },
    {
        label: "entertainment",
        iconIDs: [
            poiClassificationToIconID.CINEMA,
            poiClassificationToIconID.THEATER,
            poiClassificationToIconID.MOVIE_THEATER,
            poiClassificationToIconID.NIGHTLIFE,
            poiClassificationToIconID.CONCERT_HALL,
            poiClassificationToIconID.ENTERTAINMENT,
            poiClassificationToIconID.CLUB_ASSOCIATION,
            poiClassificationToIconID.CASINO
        ]
    },
    {
        label: "sports_leisure",
        iconIDs: [
            poiClassificationToIconID.SPORTS_CENTER,
            poiClassificationToIconID.WATER_SPORT,
            poiClassificationToIconID.SWIMMING_POOL,
            poiClassificationToIconID.GOLF_COURSE,
            poiClassificationToIconID.STADIUM,
            poiClassificationToIconID.BEACH,
            poiClassificationToIconID.ICE_SKATING_RINK,
            poiClassificationToIconID.LEISURE_CENTER,
            poiClassificationToIconID.MOUNTAIN_PASS,
            poiClassificationToIconID.MOUNTAIN_PEAK
        ]
    },
    {
        label: "education",
        iconIDs: [
            poiClassificationToIconID.SCHOOL,
            poiClassificationToIconID.COLLEGE_UNIVERSITY,
            poiClassificationToIconID.LIBRARY
        ]
    },
    {
        label: "government",
        iconIDs: [
            poiClassificationToIconID.GOVERNMENT_OFFICE,
            poiClassificationToIconID.COURTHOUSE,
            poiClassificationToIconID.EMBASSY,
            poiClassificationToIconID.FIRE_STATION_BRIGADE,
            poiClassificationToIconID.POLICE_STATION
        ]
    }
];

export const POICategoryGroupIconIDs = POICategoryGroups.map((category) => category.iconIDs);
