export const getLocalStorageValue = <T>(key: string, initialValue: T = null) => {
    return (localStorage.getItem(key) || initialValue) as T;
};

export const getLocalStorageBoolean = (key: string, initialValue = false): boolean => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? "true" === storedValue.toLowerCase() : initialValue;
};

export const getLocalStorageJson = <T>(key: string, initialValue: T = null): T => {
    try {
        const storedValue = localStorage.getItem(key);
        return storedValue ? (JSON.parse(storedValue) as T) : initialValue;
    } catch (err) {
        return initialValue;
    }
};

export const getLocalStorageJsonArray = <T>(key: string, initialValue: T[] = []): T[] =>
    getLocalStorageJson<T[]>(key, initialValue);

export const setLocalStorageValue = (key: string, value: unknown) => {
    localStorage.setItem(key, String(value));
};

export const setLocalStorageJson = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};
